import api from "Services/api";
import {
  FeatureFlagDataProps,
  RowItemProps,
  TableDataProps,
  bodyEditFeatureFlag,
  resData,
} from "./types";

export const getFeatureFlagData = async () => {
  const { data } = await api.get<FeatureFlagDataProps>(
    "/motorista/listar-bases"
  );

  const tableData = data.bases.map((base) => {
    const row: RowItemProps = {
      id: base.codigoBaseAbadi.toString(),
      cod: base.codigoBaseAbadi.toString(),
      loadBase: base.nomeBase,
      invoicesCIF: false,
      invoicesFOB: false,
      billingCIF: false,
      billingFOB: false,
      nextTrips: false,
      scheduling: false,
      actions: "Ações",
    };
    return { row };
  });

  tableData.forEach(({ row }) => {
    data.funcionalidades.forEach((feature) => {
      if (feature.status === 1) {
        switch (feature.nome) {
          case "faturamento-cif":
            row.billingCIF = true;
            break;
          case "faturamento-fob":
            row.billingFOB = true;
            break;
          case "canhoto-cif":
            row.invoicesCIF = true;
            break;
          case "canhoto-fob":
            row.invoicesFOB = true;
            break;
          case "reagendamento":
            row.scheduling = true;
            break;
          case "agendamento":
            row.nextTrips = true;
            break;
          default:
            break;
        }
      } else if (feature.bases.includes(+row.id)) {
        switch (feature.nome) {
          case "faturamento-cif":
            row.billingCIF = true;
            break;
          case "faturamento-fob":
            row.billingFOB = true;
            break;
          case "canhoto-cif":
            row.invoicesCIF = true;
            break;
          case "canhoto-fob":
            row.invoicesFOB = true;
            break;
          case "reagendamento":
            row.scheduling = true;
            break;
          case "agendamento":
            row.nextTrips = true;
            break;
          default:
            break;
        }
      }
    });
  });

  return { tableData, functionalities: data.funcionalidades };
};

export const EditFeatureFlag = async (
  listFeatureFlagDepois: TableDataProps[],
  listFeatureFlagAntes: TableDataProps[],
  data: resData
) => {
  // id - 1: reagendamento, 2: canhoto, 3: faturamento
  // status - 0: Inativa, 1: Ativa em todas as bases, 2: Parcial apenas algumas bases
  const token = localStorage.getItem("token");
  const tokenParse = JSON.parse(token ?? "");

  const body: bodyEditFeatureFlag = {
    usuario: tokenParse.idTokenClaims.name.split(" ")[0],
    funcionalidades: [
      {
        id: 1,
        status: data.scheduling,
        addBases: [],
        delBases: [],
      },
      {
        id: 4,
        status: data.nextTrips,
        addBases: [],
        delBases: [],
      },
      {
        id: 6,
        status: data.billingCIF,
        addBases: [],
        delBases: [],
      },
      {
        id: 3,
        status: data.billingFOB,
        addBases: [],
        delBases: [],
      },
      {
        id: 2,
        status: data.invoicesCIF,
        addBases: [],
        delBases: [],
      },
      {
        id: 5,
        status: data.invoicesFOB,
        addBases: [],
        delBases: [],
      },
    ],
  };

  listFeatureFlagDepois.forEach((itemDepois) => {
    listFeatureFlagAntes.forEach((itemAntes) => {
      if (itemDepois.row.id === itemAntes.row.id) {
        // Para cada funcionalidade, verifica se houve mudança e atualiza os arrays corretamente
        if (itemDepois.row.invoicesCIF !== itemAntes.row.invoicesCIF) {
          if (itemDepois.row.invoicesCIF) {
            body.funcionalidades[4].addBases.push(+itemDepois.row.id);
          } else {
            body.funcionalidades[4].delBases.push(+itemDepois.row.id);
          }
        }

        if (itemDepois.row.invoicesFOB !== itemAntes.row.invoicesFOB) {
          if (itemDepois.row.invoicesFOB) {
            body.funcionalidades[5].addBases.push(+itemDepois.row.id);
          } else {
            body.funcionalidades[5].delBases.push(+itemDepois.row.id);
          }
        }

        if (itemDepois.row.billingCIF !== itemAntes.row.billingCIF) {
          if (itemDepois.row.billingCIF) {
            body.funcionalidades[2].addBases.push(+itemDepois.row.id);
          } else {
            body.funcionalidades[2].delBases.push(+itemDepois.row.id);
          }
        }

        if (itemDepois.row.billingFOB !== itemAntes.row.billingFOB) {
          if (itemDepois.row.billingFOB) {
            body.funcionalidades[3].addBases.push(+itemDepois.row.id);
          } else {
            body.funcionalidades[3].delBases.push(+itemDepois.row.id);
          }
        }

        if (itemDepois.row.scheduling !== itemAntes.row.scheduling) {
          if (itemDepois.row.scheduling) {
            body.funcionalidades[0].addBases.push(+itemDepois.row.id);
          } else {
            body.funcionalidades[0].delBases.push(+itemDepois.row.id);
          }
        }

        if (itemDepois.row.nextTrips !== itemAntes.row.nextTrips) {
          if (itemDepois.row.nextTrips) {
            body.funcionalidades[1].addBases.push(+itemDepois.row.id);
          } else {
            body.funcionalidades[1].delBases.push(+itemDepois.row.id);
          }
        }
      }
    });
  });

  try {
    const res = await api.put("/motorista/atualizar-base", body);

    return res;
  } catch (error) {
    throw error;
  }
};
