import classes from "./styles.module.scss";

const Loading = () => {

  return (
    <div className="vw-100 vh-100 d-flex flex-column justify-content-center text-center align-items-center">
      <div
        className={`spinner-border text-center mt-3 ${classes.spinner}`}
        role="status"
      />
    </div>
  );
};

export default Loading;
