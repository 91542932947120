import { MenuItem, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';

interface Option {
    value: string | undefined;
    label: string;
}

interface InputFloatingLabelProps {
    label: string;
    id: string;
    onChange: (e?: any) => void;
    type?: string;
    error?: boolean;
    value?: string;
    options?: Option[];
    helperText?: string;
}

const CssTextField = styled(TextField)({
    '& label.Mui-focused': {
        color: '#0068FF',
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: '#58595B',
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#58595B',
            borderRadius: '100px',
        },
        '&:hover fieldset': {
            borderColor: '#58595B',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#0068FF',
        },
    },
});

const InputFloatingLabel = ({
    label,
    id,
    onChange,
    type = 'text',
    options = [],
    value,
    error = false,
    helperText,
}: InputFloatingLabelProps) => (
    <CssTextField
        size="medium"
        style={{ width: '100%', fontWeight: 'lighter' }}
        label={label}
        id={id}
        type={type}
        value={value}
        select={type === 'select'}
        error={error}
        onChange={(e) => {
            onChange(e);
        }}
        helperText={helperText}
    >
        {type === 'select' &&
            options.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                    {option.label}
                </MenuItem>
            ))}
    </CssTextField>
);

export default InputFloatingLabel;
