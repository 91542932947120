import styled from 'styled-components'
import { ButtonProps } from './types'

export const StyledButton = styled.button<ButtonProps>`
    background-color: ${({ disabled, typeStyle, theme }) =>
        disabled
            ? theme.colors.grey_2
            : typeStyle === 'default'
            ? theme.colors.blue_ipiranga
            : theme.colors.input_background};
    color: ${({ disabled, typeStyle, theme }) =>
        disabled
            ? theme.colors.grey_1
            : typeStyle === 'default'
            ? theme.colors.white
            : theme.colors.blue_tertiary};
    border: 1px solid
        ${({ disabled, typeStyle, theme }) =>
            disabled
                ? typeStyle === 'outlined'
                    ? theme.colors.grey_1
                    : theme.colors.grey_2
                : typeStyle === 'outlined'
                ? theme.colors.blue_tertiary
                : theme.colors.blue_ipiranga};
    border-radius: 100px;
    padding: 10px 15px;
    font-size: 18px;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;

    &:hover {
        background-color: ${({ disabled, typeStyle, theme }) =>
            disabled
                ? null
                : typeStyle === 'default'
                ? theme.colors.blue_tertiary
                : theme.colors.grey_2};
    }
`

export const StyledSpan = styled.span`
    white-space: nowrap;
`