import styles from "./styles.module.scss";
interface ButtonProps {
  children: React.ReactElement | React.ReactElement[] | string;
  customClassName?: string;
  classColorButton?: string;
  onClick?: () => void;
  testId?: string;
  isLoading?: boolean;
  disabled?: boolean;
}

const Button = ({
  children,
  customClassName,
  classColorButton,
  onClick = () => {},
  testId,
  isLoading = false,
  disabled,
}: ButtonProps) => (
  <div
    data-testid={testId}
    className={`${styles.button} ${customClassName} `}
    onClick={disabled ? () => {} : () => onClick()}
  >
    {isLoading && <div className={`${styles.rotate} ${styles.loading}`} />}
    {!isLoading && (
      <span className={`${styles.fileButtonText}  ${classColorButton}`}>
        {children}
      </span>
    )}
  </div>
);

export default Button;
