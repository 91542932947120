import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface InvoiceStateProps {
  dataImages: any[];
}

const initialState: InvoiceStateProps = {
  dataImages: [],
};

export const invoiceFlagSlice = createSlice({
  name: "Invoice",
  initialState,
  reducers: {
    setStateInvoiceSelected: (
      state,
      action: PayloadAction<any[]>
    ) => {
      state.dataImages = action.payload
    },
    cleanState: (state) => {
      state.dataImages = [];
    },
  },
});

export const { setStateInvoiceSelected, cleanState } =
  invoiceFlagSlice.actions;

export default invoiceFlagSlice.reducer;
