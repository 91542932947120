import api from "Services/api";
import dayjs from "dayjs";
import { GetVersionProps, VersionProps, VersioningProps } from "pages/Versioning/types";

export const getVersioning = async ({
  page = 0,
  endDateLocale = '',
  startDateLocale = '',
  obligateChecked = null,
  notObligateChecked = null
}: any): Promise<VersioningProps> => {
  try {
    let urlComplement = "?page=" + page;

    if (!!startDateLocale && !!endDateLocale) urlComplement += "&dateFrom=" + startDateLocale + "&dateTo=" + endDateLocale;
    else if (!!startDateLocale) urlComplement += "&dateFrom=" + startDateLocale + "&dateTo=" + startDateLocale;
    else if (!!endDateLocale) urlComplement += "&dateFrom=" + endDateLocale + "&dateTo=" + endDateLocale;

    if (!!obligateChecked) urlComplement += "&searchObligate=true";

    if (!!notObligateChecked) urlComplement += "&searchNotObligate=true";


    const { data } = await api.get<VersioningProps>(
      "/motorista/listar-Versionamento" + urlComplement
    );
    return data;
  } catch (error) {
    return {
      quantidadePaginas: 0,
      quantidade: 0,
      versions: [],
    };
  }
};

export const addVersioning = async (data: GetVersionProps) => {
  const token = localStorage.getItem("token");

  const tokenParse = JSON.parse(token ?? "");

  const body = {
    usuario: tokenParse.idTokenClaims.name.split(" ")[0],
    sistemaOperacional: data.sistemaOperacional.value,
    versao: data.versao.value,
    status: Number(data.status.value),
    dataLancamento: dayjs(data.dataLancamento.value).format("YYYY-MM-DD") || null,
    obrigatorio: data.obrigatorio.value ? data.obrigatorio.value : "N",
  };
  try {
    await api.post("/motorista/incluir-versionamento", body);
  } catch (error) {
    
    throw new Error()
  }
};

export const editVersioning = async (data: VersionProps) => {
  const token = localStorage.getItem("token");
  // json
  const tokenParse = JSON.parse(token ?? "");
  const body = {
    usuario: tokenParse.idTokenClaims.name.split(" ")[0],
    sistemaOperacional: data.sistemaOperacional.value,
    versao: data.versao.value,
    status: Number(data.status.value),
    dataLancamento: data.dataLancamento.value || null,
    obrigatorio: data.obrigatorio.value,
    idVersao: data.idVersao,
  };
  try {
    await api.put("/motorista/atualizar-versionamento", body);

  } catch (error) {
    
    throw new Error()
  }
};

export const removeVersioning = async (idVersao: number) => {
  const token = localStorage.getItem("token");

  const tokenParse = JSON.parse(token ?? "");

  const config = {
    headers: {
      idVersao: idVersao,
      usuario: tokenParse.idTokenClaims.name.split(" ")[0],
    },
  };

  try {
    await api.put("/motorista/desativar-versionamento", {}, config);
    return true;
  } catch (error) {
    throw new Error("Erro ao desativar versão");
  }
};