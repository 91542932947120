import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TableDataProps } from "Functions/featureFlag/types";

interface FeatureFlagStateProps {
  tableData: TableDataProps[];
}

const initialState: FeatureFlagStateProps = {
  tableData: [],
};

export const featureFlagSlice = createSlice({
  name: "FeatureFlag",
  initialState,
  reducers: {
    setStateFeatureFlagSelected: (
      state,
      action: PayloadAction<TableDataProps[]>
    ) => {
      state.tableData = action.payload
    },
    cleanState: (state) => {
      state.tableData = [];
    },
  },
});

export const { setStateFeatureFlagSelected, cleanState } =
  featureFlagSlice.actions;

export default featureFlagSlice.reducer;
