export const BREADCRUMBS = [
  {
    title: "Gestão de funcionalidades",
    route: "/gestao-funcionalidades",
    parents: [
      {
        title: "Portal",
        route: "/inicio",
      },
    ],
  },
  {
    title: "Versionamento",
    route: "/versionamento",
    parents: [
      {
        title: "Portal",
        route: "/inicio",
      },
    ],
  },
  {
    title: "Canhoto Digital",
    route: "/canhoto-digital",
    parents: [
      {
        title: "Portal",
        route: "/inicio",
      },
    ],
  },
];
