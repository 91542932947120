export const columnKeyToColType: {
  [key: string]:
      | 'invoicesCIF'
      | 'invoicesFOB'
      | 'billingCIF'
      | 'billingFOB'
      | 'scheduling'
      | 'nextTrips'
} = {
  invoicesCIF: 'invoicesCIF',
  invoicesFOB: 'invoicesFOB',
  billingCIF: 'billingCIF',
  billingFOB: 'billingFOB',
  scheduling: 'scheduling',
  nextTrips: 'nextTrips',
}

export interface EditCheckboxItemProps {
  cod: string
  col: 'invoicesCIF' | 'invoicesFOB' | 'billingCIF' | 'billingFOB' | 'scheduling' | 'nextTrips'
}

export interface FillAllFieldsProps {
  col: 'invoicesCIF' | 'invoicesFOB' | 'billingCIF' | 'billingFOB' | 'scheduling' | 'nextTrips'
  conditionToFillIn: undefined | boolean
}

export interface TableColumn {
  name: string
  key: string
  sortable?: boolean
  sortKey?: string
  sortFunction?: (sortValue: boolean | undefined) => void
  sortValue?: boolean | undefined
  type?: 'checkbox'
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  checked?: boolean
}