import { Document, Image, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import LogoIPP from '../../assets/logo-ipiranga-azul.png';

const styles = StyleSheet.create({
    page: {
        backgroundColor: '#fff',
        color: 'black',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    sectionHeader: {
        flexDirection: 'column',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    section: {
        flexDirection: 'column',
        flex: 1,
        alignItems: 'center',
    },
    viewer: {
        width: '100%',
        height: '40rem',
    },
    text: {
        fontSize: '24px',
        lineHeight: 1.5,
        fontWeight: 'bold',
    },
    title: {
        fontSize: '18px',
        fontWeight: 'bold',
        textAlign: 'center',
    },
    row: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        margin: 10,
        padding: 10,
    },
    rowAlignCenter: {
        alignItems: 'center',
    },
    rowCenter: {
        justifyContent: 'center',
    },
    image: {
        width: 187,
        height: 51,
    },
    qrcode: {
        width: 350,
        height: 350,
    },
    imageSignature: {
        width: 350,
        height: 200,
    },
    center: {
        textAlign: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    border: {
        width: '95%',
        height: '95%',
        borderRadius: 24,
        padding: 50,
        border: '20px solid #ffcf00',
    },
});

function QRCodePDF({ code, qrcode }: { code: string; qrcode: string }) {
    return (
        <Document
            title="QRCode Para Faturamento"
            author="Ipiranga Produtos de Petróleo SA"
            subject={`Código QRCode: ${code}`}
            producer="Ipiranga Produtos de Petróleo SA"
            creator="Ipiranga Produtos de Petróleo SA"
            key={`QRCode-${code}`}
            language="pt-BR"
        >
            <Page break size="A4" style={styles.page}>
                <View style={styles.border}>
                    <View
                        style={{
                            ...styles.row,
                            ...styles.rowAlignCenter,
                            padding: '0 10',
                            alignItems: 'flex-end',
                        }}
                    >
                        <View style={styles.sectionHeader}>
                            <Image style={styles.image} src={LogoIPP} />
                        </View>
                    </View>

                    <View
                        style={{
                            ...styles.section,
                            justifyContent: 'space-evenly',
                        }}
                    >
                        <div style={styles.center}>
                            <Text style={styles.text}>Leia o código a baixo para solicitar</Text>
                            <Text style={styles.text}>impressão das notas fiscais</Text>
                        </div>

                        <Image style={styles.qrcode} src={qrcode} />

                        <Text style={styles.text}>Código QRCode: {code}</Text>
                    </View>
                </View>
            </Page>
        </Document>
    );
}

export default QRCodePDF;
