import { createGlobalStyle } from 'styled-components';

// Definindo a fonte globalmente
const GlobalStyles = createGlobalStyle`
 @font-face {
  font-family: "Light"; /*Can be any text*/
  src: local("IpirangaTextos-Light"),
    url("../../fonts/IpirangaTextos-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Bold"; /*Can be any text*/
  src: local("IpirangaTextos-Bold"),
    url("../../fonts/IpirangaTextos-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Medium"; /*Can be any text*/
  src: local("IpirangaTextos-Medium"),
    url("../../fonts/IpirangaTextos-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Bold Titulos"; /*Can be any text*/
  src: local("IpirangaTitulos-Bold"),
    url("../../fonts/IpirangaTitulos-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Regular"; /*Can be any text*/
  src: local("IpirangaTextos-Regular"),
    url("../../fonts/IpirangaTextos-Regular.ttf") format("truetype");
}

 body {
    font-family: 'Roboto', sans-serif;
 }
`;

export default GlobalStyles;