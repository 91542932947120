/* eslint-disable react-hooks/exhaustive-deps */
import { Row, Table } from 'reactstrap';

import { FormControl, Pagination } from '@mui/material';
import { addVersioning, editVersioning, getVersioning, removeVersioning } from 'Functions';
import { resData } from 'Functions/versioning/types';
import ButtonComponent from 'components/ButtonComponent';

import InputDate from 'components/InputDate';
import InputFloatingLabel from 'components/InputFloatingLabel';
import { ContainerPagination } from 'components/ListInvoicesComponent/styled';
import CustomModal from 'components/Modal';
import { AntSwitch } from 'components/Switch';
import dayjs, { Dayjs } from 'dayjs';
import { useEffect, useState, useRef } from 'react';
import { Form } from 'react-bootstrap';
import { renderToast } from 'utils/basic/renderToast';
import DeleteIcon from '../../assets/delete-icon.svg';
import DownArrow from '../../assets/down-arrow.svg';
import EditIcon from '../../assets/edit-icon.svg';
import ConfirmationModal from '../../components/ConfirmationModal';
import styles from './styles.module.scss';
import { GetVersionProps, VersioningProps } from './types';

const initialStateCols = {
    status: false,
    dataLancamento: false,
    obrigatorio: false,
    sistemaOperacional: false,
    versao: false,
};

const Versioning = () => {
    const orderBy = useRef('');
    const [initialDataTb, setInitialDatatb] = useState<resData[]>([]);
    const [dataTb, setDatatb] = useState<resData[]>([]);
    const [itensPaginados, setItensPaginados] = useState<resData[]>([]);
    const [offset, setOffset] = useState<number>(0);
    const [totalPages, setTotalPages] = useState(1);
    const [showModalAddVersion, setShowModalAddVersion] = useState<boolean>(false);
    const [showConfirmSendForm, setShowConfirmSendForm] = useState<boolean>(false);
    const [showConfirmRemove, setShowConfirmRemove] = useState<boolean>(false);
    const [isClearingDate, setIsClearingDate] = useState<boolean>(false);

    const [idVersionToRemove, setIdVersionToRemove] = useState<number>(0);
    const [formVersion, setFormVersion] = useState<GetVersionProps>({
        versao: { value: '', error: false },
        sistemaOperacional: { value: '', error: false },
        status: { value: '', error: false },
        dataLancamento: { value: null, error: false },
        obrigatorio: { value: '', error: false },
        idVersao: 0,
    } as GetVersionProps);

    const [sortedCols, setSortedCols] = useState(initialStateCols);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isLoadingPage, setIsLoadingPage] = useState<boolean>(false);
    const [obligateChecked, setObligateChecked] = useState<boolean>(false);
    const [notObligateChecked, setNotObligateChecked] = useState<boolean>(false);
    const [startDateLocale, setStartDateLocale] = useState<Dayjs | null>(null);
    const [endDateLocale, setEndDateLocale] = useState<Dayjs | null>(null);
    const [showIsEditing, setShowIsEditing] = useState<boolean>(false);
    const [errorDate, setErrorDate] = useState({
        startDate: false,
        endDate: false,
    });

    function paginarItens(itens: resData[], itensPorPagina: number): Array<resData[]> {
        const totalPags = Math.ceil(itens.length / itensPorPagina);
        let paginas: Array<resData[]> = [];

        for (let i = 0; i < totalPags; i++) {
            const inicio = i * itensPorPagina;
            const fim = Math.min(inicio + itensPorPagina, itens.length);
            paginas.push(itens.slice(inicio, fim));
        }

        return paginas;
    }

    const sortByColumn = (
        sort: boolean,
        col: keyof (typeof dataTb)[0],
        setFunction: (sort: boolean) => void
    ) => {
        if (!sort) {
            setFunction(true);
        } else {
            setFunction(false);
        }
        orderBy.current = col;
        const textCompare = (array: resData[], sort: boolean, field: keyof (typeof dataTb)[0]) => {
            array.sort((a, b) => {
                if (sort) {
                    return (a[field] as string)?.localeCompare(b[field] as string);
                } else {
                    return (b[field] as string)?.localeCompare(a[field] as string);
                }
            });
        };
        const defaultCompare = (
            array: resData[],
            sort: boolean,
            field: keyof (typeof dataTb)[0]
        ) => {
            array.sort((a, b) => {
                if (sort) {
                    return (a[field] as number) - (b[field] as number);
                } else {
                    return (b[field] as number) - (a[field] as number);
                }
            });
        };

        const dateCompare = (array: resData[], sort: boolean) => {
            array.sort((a, b) => {
                if (sort) {
                    return dayjs(a.dataLancamento).diff(dayjs(b.dataLancamento));
                } else {
                    return dayjs(b.dataLancamento).diff(dayjs(a.dataLancamento));
                }
            });
        };

        const data = [...dataTb];

        const mapFuncs = {
            versao: () => textCompare(data, sort, col),
            sistemaOperacional: () => textCompare(data, sort, col),
            status: () => defaultCompare(data, sort, col),
            dataLancamento: () => dateCompare(data, sort),
            obrigatorio: () => textCompare(data, sort, col),
        };

        mapFuncs[col as 'versao']();

        setDatatb(data);

        const paginatedSortedItems = paginarItens(data, 10);
        setItensPaginados(paginatedSortedItems[offset]);
    };

    const sortCaret = (field: string, isToggled: boolean) => {
        return (
            <img
                style={{
                    transform: `rotate(${
                        field === orderBy.current && isToggled ? '-180deg' : '0deg'
                    })`,
                    transition: 'all 300ms ease-in-out',
                }}
                src={DownArrow}
                alt="setas"
            />
        );
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const getVersioningData = async (isClearing?: boolean) => {
        setIsLoading(true);

        try {
            const tableData: VersioningProps = await getVersioning({
                page: isClearing ? 0 : offset,
                endDateLocale: endDateLocale ? dayjs(endDateLocale).format('YYYY-MM-DD') : null,
                startDateLocale: startDateLocale
                    ? dayjs(startDateLocale).format('YYYY-MM-DD')
                    : null,
                obligateChecked,
                notObligateChecked,
            });

            const filteredVersions = tableData?.versions.filter((version) => {
                const isObligatoryMatch = version.obrigatorio === 'S' ? true : false;
                const isNotObligatoryMatch = version.obrigatorio === 'N' ? true : false;
                const launchDate = dayjs(version.dataLancamento);
                const isWithinDateRange =
                    (!startDateLocale ||
                        launchDate.isAfter(dayjs(startDateLocale)) ||
                        launchDate.isSame(dayjs(startDateLocale))) &&
                    (!endDateLocale ||
                        launchDate.isBefore(dayjs(endDateLocale)) ||
                        launchDate.isSame(dayjs(endDateLocale)));

                return (
                    isWithinDateRange ||
                    (!version.obrigatorio &&
                        (obligateChecked === isObligatoryMatch ||
                            notObligateChecked === isNotObligatoryMatch))
                );
            });

            if (orderBy.current === 'obrigatorio') {
                filteredVersions.sort((a, b) => {
                    if (!sortedCols.obrigatorio) {
                        return (a.obrigatorio as string)?.localeCompare(b.obrigatorio as string);
                    } else {
                        return (b.obrigatorio as string)?.localeCompare(a.obrigatorio as string);
                    }
                });
            }

            setTotalPages(Math.ceil(filteredVersions.length / 10));
            setInitialDatatb(filteredVersions);
            setItensPaginados(paginarItens(filteredVersions, 10)[offset]);
            setDatatb(filteredVersions);
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    const formatDate = (date: string) => {
        const [year, month, day] = date.split('-');
        return `${day}/${month}/${year}`;
    };

    const onChangeForm = (value: string, key: string) => {
        setFormVersion({ ...formVersion, [key]: { value, error: false } });
    };

    const validateForm = () => {
        let hasError = false;
        const newForm = { ...formVersion };
        if (!newForm.versao.value) {
            newForm.versao.error = true;
            hasError = true;
            newForm.versao.textError = 'Campo obrigatório';
        }
        initialDataTb.forEach((item) => {
            if (
                item.versao === newForm.versao.value &&
                item.sistemaOperacional === newForm.sistemaOperacional.value
            ) {
                if (newForm.idVersao === item.idVersao) return;
                newForm.versao.error = true;
                hasError = true;
                newForm.versao.textError =
                    'Esta versão já existe para o sistema operacional, digite uma nova.';
            }
        });
        if (!newForm.sistemaOperacional.value) {
            newForm.sistemaOperacional.error = true;
            hasError = true;
            newForm.sistemaOperacional.textError = 'Campo obrigatório';
        }
        if (!newForm.status.value) {
            newForm.status.error = true;
            hasError = true;
            newForm.status.textError = 'Campo obrigatório';
        }
        if (newForm.dataLancamento.value === null) {
            newForm.dataLancamento.error = true;
            hasError = true;
            newForm.dataLancamento.textError = 'Campo obrigatório';
        }

        setFormVersion(newForm);
        return hasError;
    };

    const onClearForm = () => {
        setFormVersion({
            versao: { value: '', error: false },
            sistemaOperacional: { value: '', error: false },
            status: { value: '', error: false },
            dataLancamento: { value: null, error: false },
            obrigatorio: { value: '', error: false },
        } as GetVersionProps);
        setShowIsEditing(false);
    };

    const sendForm = async () => {
        setShowConfirmSendForm(false);
        setIsLoadingPage(true);

        try {
            if (!formVersion.idVersao) {
                await addVersioning(formVersion);
                renderToast('Versão incluída com sucesso.', { type: 'success' });
                getVersioningData();
            } else {
                await editVersioning({
                    versao: { value: formVersion.versao.value.trim(), error: false },
                    sistemaOperacional: {
                        value: formVersion.sistemaOperacional.value,
                        error: false,
                    },
                    status: { value: formVersion.status.value, error: false },
                    dataLancamento: {
                        value: formVersion.dataLancamento.value
                            ? formVersion.dataLancamento.value
                                  ?.add(dayjs().utcOffset() * -1, 'minutes')
                                  .format('YYYY-MM-DD')
                            : '',
                        error: false,
                    },
                    obrigatorio: { value: formVersion.obrigatorio.value, error: false },
                    idVersao: formVersion.idVersao,
                });
                getVersioningData();
                renderToast('Edição feita com sucesso.', { type: 'success' });
            }
        } catch (err) {
            let message =
                'Desculpe, estamos com problema técnico. Por favor, aguarde alguns minutos e tente novamente.';
            renderToast(message, { type: 'error' });
        } finally {
            onClearForm();
            setIsLoadingPage(false);
        }
    };

    const onEdit = (version: resData) => {
        const date = new Date(version.dataLancamento);
        const userTimezoneOffset = date.getTimezoneOffset() * 60000;
        const dataLancamento = dayjs(new Date(date.getTime() + userTimezoneOffset));
        setFormVersion({
            versao: { value: version.versao.trim(), error: false },
            sistemaOperacional: { value: version.sistemaOperacional, error: false },
            status: { value: version.status.toString(), error: false },
            dataLancamento: { value: dataLancamento, error: false },
            obrigatorio: { value: version.obrigatorio, error: false },
            idVersao: version.idVersao,
        });
        setShowModalAddVersion(true);
        setShowIsEditing(true);
    };

    const onShowConfirmRemove = (id: number) => {
        setShowConfirmRemove(true);
        setIdVersionToRemove(id);
    };

    const onRemove = async () => {
        setIsLoadingPage(true);
        try {
            await removeVersioning(idVersionToRemove);
            setIsLoadingPage(false);
            renderToast('Versão excluída com sucesso.', { type: 'success' });
        } catch (error) {
            let message =
                'Desculpe, estamos com problema técnico. Por favor, aguarde alguns minutos e tente novamente.';
            setIsLoadingPage(false);
            renderToast(message, { type: 'error' });
        }

        setShowConfirmRemove(false);
        setIsLoadingPage(false);
        getVersioningData();
    };

    const onChangeMandatory = (version: resData) => {
        setFormVersion({
            versao: { value: version.versao, error: false },
            sistemaOperacional: { value: version.sistemaOperacional, error: false },
            status: { value: version.status.toString(), error: false },
            dataLancamento: {
                value: dayjs(new Date(version.dataLancamento)),
                error: false,
            },

            obrigatorio: {
                value: version.obrigatorio === 'S' ? 'N' : 'S',
                error: false,
            },
            idVersao: version.idVersao,
        });
        setShowConfirmSendForm(true);
    };

    const isValidDate = (value: string) => {
        let formattedDate = value;

        if (formattedDate?.includes('Invalid')) return false;
        if (formattedDate?.split('-')[0][0] === '0') return false;

        return true;
    };

    useEffect(() => {
        const paginatedItems = paginarItens(dataTb, 10);
        setItensPaginados(paginatedItems[offset]);
    }, [offset]);

    useEffect(() => {
        getVersioningData();
    }, [obligateChecked, notObligateChecked]);

    useEffect(() => {
        if (isClearingDate) {
            setOffset(0);
            getVersioningData(true);
            setIsClearingDate(false);
            setErrorDate({ endDate: false, startDate: false });
        }
    }, [isClearingDate]);

    return (
        <div className={`w-100 h-100 ${styles.container}`} data-testid="container-page">
            <h4>Controle das atualizações do APP</h4>

            <div className={styles.borderTable} data-testid="border-table">
                <div className={styles.contentHeader} data-testid="content-header">
                    <ButtonComponent
                        data-testid="button-new-version"
                        className={styles.buttonInclude}
                        onClick={() => {
                            setShowModalAddVersion(true);
                        }}
                    >
                        Incluir versão
                    </ButtonComponent>
                    <div className={styles.inputs} data-testid="input-container">
                        <div className={styles.row}>
                            <Form.Check
                                style={{ marginRight: 12, color: '#0068FF' }}
                                checked={obligateChecked}
                                onChange={() => {
                                    setOffset(0);
                                    setObligateChecked(!obligateChecked);
                                }}
                                label="Obrigatório"
                            />
                            <Form.Check
                                style={{ marginRight: 12, color: '#0068FF' }}
                                checked={notObligateChecked}
                                onChange={() => {
                                    setOffset(0);
                                    setNotObligateChecked(!notObligateChecked);
                                }}
                                label="Não Obrigatório"
                            />
                        </div>

                        <span className={styles.titleDate}>Data de Lançamento:</span>

                        <div className={styles.row}>
                            <div className={styles.calendarArea}>
                                <div className={styles.containerInputDate}>
                                    <InputDate
                                        testId="calendar-input"
                                        label="De"
                                        onChange={(inputDate) => {
                                            if (
                                                !inputDate ||
                                                inputDate.toString().trim() === '' ||
                                                !isValidDate(inputDate.format('YYYY-MM-DD'))
                                            ) {
                                                setStartDateLocale(null);
                                                return;
                                            }

                                            if (inputDate?.isAfter(endDateLocale)) {
                                                setErrorDate((prev) => ({
                                                    ...prev,
                                                    startDate: true,
                                                }));
                                                setStartDateLocale(null);
                                                return;
                                            }
                                            setErrorDate((prev) => ({ ...prev, startDate: false }));
                                            setStartDateLocale(inputDate);
                                        }}
                                        value={startDateLocale}
                                        error={errorDate.startDate}
                                        helperText={'Data inicial deve ser menor que a final'}
                                    />
                                </div>
                                <div className={styles.containerInputDate}>
                                    <InputDate
                                        testId="calendar-input"
                                        label={'Até'}
                                        onChange={(inputDate) => {
                                            if (
                                                !inputDate ||
                                                inputDate.toString().trim() === '' ||
                                                !isValidDate(inputDate.format('YYYY-MM-DD'))
                                            ) {
                                                setEndDateLocale(null);
                                                return;
                                            }

                                            if (inputDate?.isBefore(startDateLocale)) {
                                                setErrorDate((prev) => ({
                                                    ...prev,
                                                    endDate: true,
                                                }));
                                                setEndDateLocale(null);
                                                return;
                                            }
                                            setErrorDate((prev) => ({ ...prev, endDate: false }));
                                            inputDate?.isValid() && setEndDateLocale(inputDate);
                                        }}
                                        value={endDateLocale}
                                        error={errorDate.endDate}
                                        helperText={'Data final deve ser maior que a inicial'}
                                    />
                                </div>
                            </div>
                            <ButtonComponent
                                disabled={!endDateLocale && !startDateLocale}
                                onClick={() => {
                                    setStartDateLocale(null);
                                    setEndDateLocale(null);
                                    setIsClearingDate(true);
                                }}
                                typeStyle="outlined"
                            >
                                Limpar Data
                            </ButtonComponent>
                            <ButtonComponent
                                onClick={() => {
                                    setOffset(0);
                                    getVersioningData(true);
                                }}
                                disabled={!startDateLocale || !endDateLocale}
                            >
                                Aplicar
                            </ButtonComponent>
                        </div>
                    </div>
                </div>

                {isLoadingPage && (
                    <div className={styles.loadingPage} data-testid="loading-page">
                        <div className={`${styles.rotate} ${styles.loading}`} />
                    </div>
                )}

                {isLoading && <div className={`${styles.rotate} ${styles.loading}`} />}
                {!isLoading && (
                    <Table data-testid="table">
                        <thead data-testid="table-head">
                            <tr>
                                <th
                                    data-testid="table-header-column"
                                    onClick={() =>
                                        sortByColumn(sortedCols.versao, 'versao', (sort) =>
                                            setSortedCols({ ...initialStateCols, versao: sort })
                                        )
                                    }
                                >
                                    Versão
                                    {sortCaret('versao', sortedCols.versao)}
                                </th>
                                <th
                                    data-testid="table-header-column"
                                    onClick={() =>
                                        sortByColumn(
                                            sortedCols.sistemaOperacional,
                                            'sistemaOperacional',
                                            (sort) =>
                                                setSortedCols({
                                                    ...initialStateCols,
                                                    sistemaOperacional: sort,
                                                })
                                        )
                                    }
                                >
                                    Sis.Operacional{' '}
                                    {sortCaret('sistemaOperacional', sortedCols.sistemaOperacional)}
                                </th>
                                <th
                                    data-testid="table-header-column"
                                    onClick={() =>
                                        sortByColumn(sortedCols.status, 'status', (sort) =>
                                            setSortedCols({ ...initialStateCols, status: sort })
                                        )
                                    }
                                >
                                    Status {sortCaret('status', sortedCols.status)}
                                </th>
                                <th
                                    data-testid="table-header-column"
                                    onClick={() =>
                                        sortByColumn(
                                            sortedCols.dataLancamento,
                                            'dataLancamento',
                                            (sort) =>
                                                setSortedCols({
                                                    ...initialStateCols,
                                                    dataLancamento: sort,
                                                })
                                        )
                                    }
                                >
                                    Data de Lançamento{' '}
                                    {sortCaret('dataLancamento', sortedCols.dataLancamento)}
                                </th>
                                <th
                                    data-testid="table-header-column"
                                    onClick={() =>
                                        sortByColumn(
                                            sortedCols.obrigatorio,
                                            'obrigatorio',
                                            (sort) =>
                                                setSortedCols({
                                                    ...initialStateCols,
                                                    obrigatorio: sort,
                                                })
                                        )
                                    }
                                >
                                    Obrigatório {sortCaret('obrigatorio', sortedCols.obrigatorio)}
                                </th>
                                <th data-testid="table-header-column">Ações</th>
                            </tr>
                        </thead>
                        <tbody data-testid="table-body">
                            {!!itensPaginados?.length &&
                                itensPaginados?.map(
                                    (
                                        {
                                            versao,
                                            sistemaOperacional,
                                            status,
                                            dataLancamento,
                                            obrigatorio,
                                            idVersao,
                                            ...resProps
                                        },
                                        index
                                    ) => (
                                        <tr key={`${idVersao}_${index}`} data-testid="table-row">
                                            <td>{versao}</td>
                                            <td>{sistemaOperacional.toUpperCase()}</td>
                                            <td>{status === 2 ? 'Prevista' : 'Lançada'}</td>
                                            <td>{formatDate(dataLancamento)}</td>
                                            <td>
                                                <AntSwitch
                                                    checked={obrigatorio === 'S'}
                                                    onChange={() =>
                                                        onChangeMandatory({
                                                            ...resProps,
                                                            idVersao,
                                                            versao,
                                                            sistemaOperacional,
                                                            status,
                                                            dataLancamento,
                                                            obrigatorio,
                                                        })
                                                    }
                                                    inputProps={{ 'aria-label': 'ant design' }}
                                                />
                                            </td>
                                            <td>
                                                <img
                                                    className={styles.button}
                                                    src={EditIcon}
                                                    onClick={() =>
                                                        onEdit({
                                                            ...resProps,
                                                            idVersao,
                                                            versao,
                                                            sistemaOperacional,
                                                            status,
                                                            dataLancamento,
                                                            obrigatorio,
                                                        })
                                                    }
                                                    alt="Editar versão"
                                                />
                                                <img
                                                    className={styles.button}
                                                    src={DeleteIcon}
                                                    onClick={() => onShowConfirmRemove(idVersao)}
                                                    alt="Excluir versão"
                                                />
                                            </td>
                                        </tr>
                                    )
                                )}
                        </tbody>
                    </Table>
                )}
            </div>

            <ConfirmationModal
                testId="save-changes-base-modal"
                headerContent={
                    formVersion.idVersao
                        ? 'Você deseja salvar essa edição?'
                        : 'Você deseja incluir essa nova versão?'
                }
                onSubmit={sendForm}
                show={showConfirmSendForm}
                handleClose={() => {
                    setShowConfirmSendForm(false);
                    onClearForm();
                }}
                loading={isLoadingPage}
                hasCloseButton
                saveButtonText="Sim"
                cancelButtonText="Não"
            />

            <ConfirmationModal
                testId="save-changes-base-modal"
                headerContent="Você deseja excluir essa versão?"
                onSubmit={onRemove}
                show={showConfirmRemove}
                handleClose={() => setShowConfirmRemove(false)}
                hasCloseButton
                loading={isLoadingPage}
                saveButtonText="Sim"
                cancelButtonText="Não"
            />
            <CustomModal
                headerContent={showIsEditing ? 'Editar versão' : 'Incluir nova versão'}
                show={showModalAddVersion}
                onSubmit={() => {
                    if (validateForm()) {
                        return;
                    } else {
                        setShowModalAddVersion(false);
                        setShowConfirmSendForm(true);
                    }
                }}
                handleClose={() => {
                    setShowModalAddVersion(false);
                    onClearForm();
                }}
            >
                <div>
                    <div className={styles.row}>
                        <FormControl sx={{ m: 1, minWidth: 300 }}>
                            <InputFloatingLabel
                                type="text"
                                id="versao"
                                label="Versão"
                                value={formVersion.versao.value.trim()}
                                onChange={(e) => {
                                    let value = e.target.value
                                        .replace(/[^0-9.]/g, '')
                                        .trim() as string;
                                    value = value.length === 1 && value === '.' ? '' : value;
                                    value =
                                        value[value.length - 1] === '.' &&
                                        value[value.length - 2] === '.'
                                            ? value.slice(0, value.length - 1)
                                            : value;
                                    onChangeForm(value, 'versao');
                                }}
                                error={formVersion.versao.error}
                                helperText={formVersion.versao.textError}
                            />
                        </FormControl>

                        <FormControl sx={{ m: 1, minWidth: 300 }}>
                            <InputFloatingLabel
                                type="select"
                                options={[
                                    {
                                        value: 'android',
                                        label: 'Android',
                                    },
                                    {
                                        value: 'ios',
                                        label: 'IOS',
                                    },
                                ]}
                                id="sistemaOperacional"
                                label="Sistema operacional"
                                value={formVersion.sistemaOperacional.value}
                                onChange={(e) => onChangeForm(e.target.value, 'sistemaOperacional')}
                                error={formVersion.sistemaOperacional.error}
                                helperText={formVersion.sistemaOperacional.textError}
                            />
                        </FormControl>
                        <FormControl sx={{ m: 1, minWidth: 300 }}>
                            <InputFloatingLabel
                                type="select"
                                options={[
                                    {
                                        value: '2',
                                        label: 'Prevista',
                                    },
                                    {
                                        value: '1',
                                        label: 'Lançada',
                                    },
                                ]}
                                id="status"
                                label="Status"
                                value={formVersion.status.value}
                                onChange={(e) => onChangeForm(e.target.value, 'status')}
                                error={formVersion.status.error}
                                helperText={formVersion.status.textError}
                            />
                        </FormControl>

                        <FormControl sx={{ m: 1, minWidth: 300 }}>
                            <InputDate
                                label="Data de lançamento"
                                onChange={(inputDate) => {
                                    if (!isValidDate(inputDate?.format('YYYY-MM-DD') || 'Invalid'))
                                        return;
                                    setFormVersion((prevState: any) => ({
                                        ...prevState,
                                        dataLancamento: {
                                            ...prevState.dataLancamento,
                                            value: inputDate,
                                            error: false,
                                        },
                                    }));
                                }}
                                value={formVersion.dataLancamento.value}
                                error={formVersion.dataLancamento.error}
                                helperText={formVersion.dataLancamento.textError}
                            />
                        </FormControl>
                        <FormControl sx={{ m: 1, minWidth: 300 }}>
                            <div className={styles.row}>
                                <span>Obrigatório</span>
                                <AntSwitch
                                    checked={formVersion.obrigatorio.value === 'S'}
                                    onChange={(e) =>
                                        onChangeForm(e.target.checked ? 'S' : 'N', 'obrigatorio')
                                    }
                                    inputProps={{ 'aria-label': 'ant design' }}
                                />
                            </div>
                        </FormControl>
                    </div>
                </div>
            </CustomModal>

            {
                <Row>
                    <ContainerPagination>
                        <Pagination
                            color="primary"
                            size="small"
                            count={totalPages}
                            page={offset + 1}
                            onChange={(event, page) => setOffset(page - 1)}
                        />
                    </ContainerPagination>
                </Row>
            }
        </div>
    );
};

export default Versioning;
