import Modal from "react-bootstrap/Modal";
import styles from "./styles.module.scss";
import SaveCancelButtons from "../SaveCancelButtons";

interface CustomModalProps {
  show: boolean;
  handleClose?: () => void;
  children?: React.ReactElement;
  headerContent?: string;
  modalCustomClass?: string;
  onSubmit?: () => void;
  hasHeader?: boolean;
  hasFooter?: boolean;
  hasCloseButton?: boolean;
  testId?: string;
}

export default function CustomModal({
  show = false,
  handleClose = () => {},
  children,
  headerContent = "",
  onSubmit = () => {},
  hasHeader = true,
  hasFooter = true,
  hasCloseButton = true,
  modalCustomClass = "",
  testId,
}: CustomModalProps) {
  return (
    <Modal
      data-testid={testId}
      className={`${styles.modal} ${modalCustomClass}`}
      show={show}
      onHide={handleClose}
      size="xl"
    >
      {!!hasHeader && (
        <Modal.Header
          className={styles.header}
          closeButton={!!hasCloseButton}
          style={{ border: "none" }}
        >
          {!!headerContent && (
            <Modal.Title className={styles.title}>{headerContent}</Modal.Title>
          )}
        </Modal.Header>
      )}
      {!!children && (
        <Modal.Body className={styles.body}>{children}</Modal.Body>
      )}
      {!!hasFooter && (
        <Modal.Footer style={{ border: "none" }}>
          <SaveCancelButtons
            areaClassName={styles.areaClassName}
            saveFunc={onSubmit}
            cancelFunc={handleClose}
          />
        </Modal.Footer>
      )}
    </Modal>
  );
}
