/* eslint-disable no-loop-func */
import { useEffect, useState } from 'react';

import InputFloatingLabel from 'components/InputFloatingLabel';
import Alert from '../../assets/alert.svg';
import DownArrow from '../../assets/down-arrow.svg';
import UpArrow from '../../assets/up-arrow.svg';
import styles from './styles.module.scss';

import { getImageAssign, getSearchInvoices } from 'Functions';
import ExportCVSANDPDFModal from 'components/ExportCVSANDPDFModal';
import ExportPDFModal from 'components/ExportPDFModal';
import { Invoice } from 'components/ExportPDFModal/types';
import InputDate from 'components/InputDate';
import ListInvoicesComponent from 'components/ListInvoicesComponent';
import dayjs from 'dayjs';
import { Form } from 'react-bootstrap';
import { renderToast } from 'utils/basic/renderToast';
import {
    AdvancedSearchArea,
    BorderTable,
    Button,
    CalendarArea,
    Container,
    ContainerAdvancedSearch,
    ContainerButton,
    ContainerDate,
    ContainerDefaultSearch,
    ContainerFeedbackNoResult,
    ContainerTitleAdvancedSearch,
    ContainerTypeShipping,
    ContainerVolume,
    ContentAdvancedSearch,
    DefautSearchArea,
    IconAlert,
    TitleInput,
} from './styles';
import {
    FormFields,
    InvoiceFiltredModal,
    STATUS_CANHOTO,
    columnsTitles,
    initialState,
} from './types';
import { encrypt } from 'utils/basic/encrypt';

const Invoices = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [allInvoicesSelected, setAllInvoicesSelected] = useState<boolean>(false);
    const [formData, setFormData] = useState<FormFields>(initialState);
    const [listInvoices, setListInvoices] = useState<Invoice[]>([] as Invoice[]);
    const [listInvoicesSelected, setListInvoicesSelected] = useState<InvoiceFiltredModal>({
        invoices: [],
        show: false,
        type: '',
    } as InvoiceFiltredModal);
    const [page, setPage] = useState<number>(1);
    const [quantityTotalPages, SetQuantityTotalPages] = useState<number>(1);
    const [quantityInvoices, setQuantityInvoices] = useState<number>(1);
    const [CIFChecked, setCIFChecked] = useState<boolean>(false);
    const [FOBChecked, setFOBChecked] = useState<boolean>(false);
    const [isLoadingImages, setIsLoadingImages] = useState<boolean>(false);
    const [showAdvancedSearh, setShowAdvancedSearh] = useState<boolean>(false);
    const [showFeedbackFooter, setShowFeedbackFooter] = useState<boolean>(false);

    const disabledButtons = () => {
        if (
            (CIFChecked || FOBChecked) &&
            !!formData.dataEmissaoNFe.periodoDe.value &&
            !!formData.dataEmissaoNFe.periodoAte.value
        ) {
            return false;
        }
        return true;
    };

    const CarretIcon = (showAdvancedSearchInputs: boolean | undefined) => (
        <img src={showAdvancedSearchInputs ? UpArrow : DownArrow} alt="setas" />
    );

    const handleNonArrayForms = (value: any, key: any, subKey?: any) => {
        setFormData((prevFormData: any) => {
            if (key === 'modalidadeFrete' && subKey) {
                return {
                    ...prevFormData,
                    [key]: {
                        ...prevFormData[key],
                        [subKey]: value,
                    },
                };
            } else {
                return {
                    ...prevFormData,
                    [key]: { value, error: false },
                };
            }
        });
    };

    const verifyIsNumber = (value: string) => {
        const regex = /^[0-9;]*$/;
        if (regex.test(value) || value === '') {
            return true;
        }
        return false;
    };

    type Page<T> = {
        pageNumber: number;
        items: T[];
    };

    function divideIntoPages<T>(array: T[], itemsPerPage: number): Page<T>[] {
        const totalPages = Math.ceil(array.length / itemsPerPage);
        const pages = Array.from({ length: totalPages }, (_, index) => ({
            pageNumber: index + 1,
            items: array.slice(index * itemsPerPage, (index + 1) * itemsPerPage),
        }));
        return pages;
    }

    const handleGetImageS3 = async () => {
        try {
            if (listInvoicesSelected && listInvoicesSelected.invoices.length > 0) {
                setIsLoadingImages(true);

                const itemsWithIds3 = listInvoicesSelected.invoices.filter(
                    (item) => item.idS3 !== undefined
                );
                const itemsPerPage = 10;
                const pages = divideIntoPages(itemsWithIds3, itemsPerPage);

                for (let page of pages) {
                    listInvoicesSelected.invoices = page.items;
                    setListInvoicesSelected(listInvoicesSelected);
                    const dataImage = await getImageAssign(
                        page.items.map((item) => item.idS3).join(',')
                    );
                    return dataImage;
                }
            } else {
                console.error();
            }
        } catch (err) {
            renderToast(
                'Desculpe, não foi possível realizar a exportação. Tente novamente e, caso não funcione, abra um chamado.',
                { type: 'error', duration: 3000 }
            );
        }
    };

    const handleGetInvoicesData = async (itemsOfPages = 10, page = 1, requester = 'none') => {
        setIsLoading(true);
        try {
            const dataInvoices = await getSearchInvoices(
                {
                    itensPorPagina: itemsOfPages.toString(),
                    emitente: formData.emitente.value.trim().toUpperCase(),
                    cliente: formData.cliente.value.trim().toUpperCase(),
                    numeroNF: formData.numeroNF[0].value.split(';'),
                    dataEmissaoNFe: {
                        periodoDe: formData.dataEmissaoNFe.periodoDe.value
                            ? dayjs(formData.dataEmissaoNFe.periodoDe.value).format('YYYY-MM-DD')
                            : null,
                        periodoAte: formData.dataEmissaoNFe.periodoAte.value
                            ? dayjs(formData.dataEmissaoNFe.periodoAte.value).format('YYYY-MM-DD')
                            : null,
                    },
                    dataBaixaCanhoto: {
                        periodoDe: formData.dataBaixaCanhoto.periodoDe.value
                            ? dayjs(formData.dataBaixaCanhoto.periodoDe.value).format('YYYY-MM-DD')
                            : null,
                        periodoAte: formData.dataBaixaCanhoto.periodoAte.value
                            ? dayjs(formData.dataBaixaCanhoto.periodoAte.value).format('YYYY-MM-DD')
                            : null,
                    },
                    modalidadeFrete: {
                        cif: formData.modalidadeFrete.cif,
                        fob: formData.modalidadeFrete.fob,
                    },
                    statusCanhoto:
                        formData.statusCanhoto.value === 'all' ? '' : formData.statusCanhoto.value,
                    chaveNFe: formData.chaveNFe[0].value.split(';'),
                    serie: formData.serie.value,
                    produto: formData.produto.value,
                    volume: {
                        rangeDe: formData.volume.rangeDe.value,
                        rangeAte: formData.volume.rangeAte.value,
                    },
                    numeroPedido: formData.numeroPedido.value,
                    nomeTransportador: formData.nomeTransportador.value,
                    CNPJTransportador: formData.CNPJTransportador.value,
                    codigoTransportador: formData.codigoTransportador.value,
                },
                itemsOfPages,
                page
            );

            if (requester === 'none') {
                setPage(page);
                SetQuantityTotalPages(dataInvoices.quantidadePaginas);
                setQuantityInvoices(dataInvoices.quantidade);
                setListInvoices(dataInvoices.notas);
            }
            if (requester !== 'none') {
                setListInvoicesSelected({
                    invoices: dataInvoices.notas,
                    show: true,
                    type: requester,
                });
            }
            if (!dataInvoices) {
                throw new Error('Não foi possivel recuperar os dados das canhatos');
            }
            if (dataInvoices.notas.length === 0) {
                setShowFeedbackFooter(true);
            } else {
                setShowFeedbackFooter(false);
            }
        } catch (error) {
            let message =
                'Desculpe, estamos com problema técnico. Por favor, aguarde alguns minutos e tente novamente.';
            renderToast(message, { type: 'error' });
        } finally {
            setIsLoading(false);
        }
    };

    const clearFormFields = () => {
        const initialState: FormFields = {
            itensPorPagina: '',
            emitente: { value: '', error: false },
            cliente: { value: '', error: false },
            numeroNF: [{ value: '', error: false }],
            dataEmissaoNFe: {
                periodoDe: { value: null, error: false },
                periodoAte: { value: null, error: false },
            },
            dataBaixaCanhoto: {
                periodoDe: { value: null, error: false },
                periodoAte: { value: null, error: false },
            },
            modalidadeFrete: { cif: false, fob: false },
            statusCanhoto: { value: '', error: false },
            chaveNFe: [{ value: '', error: false }],
            serie: { value: '', error: false },
            produto: { value: '', error: false },
            volume: {
                rangeDe: { value: '', error: false },
                rangeAte: { value: '', error: false },
            },
            numeroPedido: { value: '', error: false },
            nomeTransportador: { value: '', error: false },
            CNPJTransportador: { value: '', error: false },
            codigoTransportador: { value: '', error: false },
        };
        setFormData(initialState);
        setListInvoices([]);
        setListInvoicesSelected({
            invoices: [],
            show: false,
            type: '',
        });
        setCIFChecked(false);
        setFOBChecked(false);
    };

    const loadImages = async (viewType: 'view' | 'pdf') => {
        let itemsToUpdate = [] as any;
        let listWithImages = [] as any;

        await listInvoicesSelected.invoices?.forEach(async (item) => {
            const hashAuthenticity =
                Math.ceil(Math.random() * 10000).toString() +
                item.geolocalizacao?.latitude.replaceAll(/\D/g, '') +
                item.geolocalizacao?.longitude.replaceAll(/\D/g, '') +
                Date.now().toString() +
                item.cliente.cnpj;
            itemsToUpdate.push({ ...item });
            if (item.idS3) {
                listWithImages.push({ ...item, hashAuthenticity });
            }
        });

        if (itemsToUpdate.length === 0) {
            setListInvoicesSelected((prev) => ({
                ...prev,
                show: false,
                type: viewType,
            }));
            setIsLoadingImages(false);
            return;
        }

        const promises = listWithImages.map(async (item: any) => {
            if (!item.idS3) {
                return item;
            }

            try {
                const dataImage = await handleGetImageS3();
                if (dataImage && Array.isArray(dataImage)) {
                    const image = dataImage.find((img) => img.id === item.idS3);
                    if (image) {
                        item.imagemS3 = image.base64;
                        item.hashSign = await encrypt(item.idS3);
                    }
                }
            } catch (error) {
                console.error('Erro ao buscar imagens:', error);
            }
            return item;
        });

        Promise.all(promises).then((updatedItems) => {
            const finalItems = [
                ...updatedItems,
                ...itemsToUpdate.filter((item: any) => !item.idS3),
            ];

            setListInvoicesSelected((prev) => ({
                ...prev,
                invoices: finalItems,
                show: true,
                type: viewType,
            }));
            setIsLoadingImages(false);
        });
    };

    const onShowModalView = async () => {
        loadImages('view');
    };

    const onShowModalPdf = () => {
        loadImages('pdf');
    };

    const onShowModalCsv = () => {
        if (
            allInvoicesSelected ||
            (!allInvoicesSelected && listInvoicesSelected.invoices.length === 0)
        ) {
            handleGetInvoicesData(quantityInvoices, 1, 'csv');
        } else
            setListInvoicesSelected((prev: any) => ({
                ...prev,
                show: true,
                type: 'csv',
            }));
    };

    const onSelectInvoice = (invoice: Invoice) => {
        const index = listInvoicesSelected.invoices.findIndex(
            (item: any) => item.numeroNF === invoice.numeroNF
        );
        if (index === -1) {
            setListInvoicesSelected({
                invoices: [...listInvoicesSelected.invoices, invoice],
                show: false,
                type: '',
            });
        } else {
            const newInvoices = listInvoicesSelected.invoices.filter(
                (item: any) => item.numeroNF !== invoice.numeroNF
            );
            setListInvoicesSelected({
                invoices: newInvoices,
                show: false,
                type: '',
            });
        }
    };

    const isValidDate = (
        value: string,
        validationError?: null | string,
        setCallback?: () => void
    ) => {
        let formattedDate = value;
        if (validationError === null) {
            setCallback && setCallback();
            return false;
        }
        if (formattedDate?.includes('Invalid')) return false;
        if (formattedDate?.split('-')[0][0] === '0') return false;

        return true;
    };

    const showFeedback = () => {
        renderToast('Exportação realizada com sucesso.', { type: 'success' });
    };

    useEffect(() => {
        if (allInvoicesSelected && !listInvoicesSelected.invoices.length) {
            setAllInvoicesSelected(false);
        }
    }, [listInvoicesSelected.invoices]);

    return (
        <Container data-testid="container-page">
            <BorderTable data-testid="border-table">
                <ContainerTypeShipping>
                    <TitleInput>Modalidade do frete*:</TitleInput>
                    <Form.Check
                        style={{ marginRight: 12, color: '#0068FF', fontWeight: 'bold' }}
                        checked={CIFChecked}
                        onChange={(e) => {
                            const isChecked = e.target.checked;
                            setCIFChecked(isChecked);
                            handleNonArrayForms(isChecked, 'modalidadeFrete', 'cif');
                        }}
                        label="CIF"
                    />
                    <Form.Check
                        style={{ marginRight: 12, color: '#0068FF', fontWeight: 'bold' }}
                        checked={FOBChecked}
                        onChange={(e) => {
                            const isChecked = e.target.checked;
                            setFOBChecked(isChecked);
                            handleNonArrayForms(isChecked, 'modalidadeFrete', 'fob');
                        }}
                        label="FOB"
                    />
                </ContainerTypeShipping>

                <ContainerDate>
                    <TitleInput>Data da baixa:</TitleInput>
                    <CalendarArea>
                        <InputDate
                            label="De"
                            testId="calendar-input"
                            onChange={(inputDate, validationError) => {
                                if (
                                    !isValidDate(
                                        inputDate?.format('YYYY-MM-DD') || 'Invalid',
                                        validationError,
                                        () =>
                                            setFormData((prevState) => ({
                                                ...prevState,
                                                dataBaixaCanhoto: {
                                                    ...prevState.dataBaixaCanhoto,
                                                    periodoDe: {
                                                        ...prevState.dataBaixaCanhoto.periodoDe,
                                                        value: null,
                                                        error: false,
                                                    },
                                                },
                                            }))
                                    )
                                ) {
                                    return;
                                }

                                setFormData((prevState) => ({
                                    ...prevState,
                                    dataBaixaCanhoto: {
                                        ...prevState.dataBaixaCanhoto,
                                        periodoDe: {
                                            ...prevState.dataBaixaCanhoto.periodoDe,
                                            value: inputDate,
                                            error: false,
                                        },
                                    },
                                }));
                                if (
                                    inputDate?.isAfter(formData.dataBaixaCanhoto.periodoAte.value)
                                ) {
                                    setFormData((prevState) => ({
                                        ...prevState,
                                        dataBaixaCanhoto: {
                                            ...prevState.dataBaixaCanhoto,
                                            periodoDe: {
                                                ...prevState.dataBaixaCanhoto.periodoDe,
                                                value: null,
                                                error: true,
                                            },
                                        },
                                    }));
                                }
                            }}
                            value={formData.dataBaixaCanhoto.periodoDe.value}
                            error={formData.dataBaixaCanhoto.periodoDe.error}
                            helperText={'Data inicial deve ser menor que a final'}
                            maxDate={dayjs(new Date())}
                        />
                    </CalendarArea>
                    <CalendarArea>
                        <InputDate
                            label="Até"
                            testId="calendar-input"
                            onChange={(inputDate, validationError) => {
                                if (
                                    !isValidDate(
                                        inputDate?.format('YYYY-MM-DD') || 'Invalid',
                                        validationError,
                                        () =>
                                            setFormData((prevState) => ({
                                                ...prevState,
                                                dataBaixaCanhoto: {
                                                    ...prevState.dataBaixaCanhoto,
                                                    periodoAte: {
                                                        ...prevState.dataBaixaCanhoto.periodoAte,
                                                        value: inputDate,
                                                        error: false,
                                                    },
                                                },
                                            }))
                                    )
                                ) {
                                    return;
                                }

                                setFormData((prevState) => ({
                                    ...prevState,
                                    dataBaixaCanhoto: {
                                        ...prevState.dataBaixaCanhoto,
                                        periodoAte: {
                                            ...prevState.dataBaixaCanhoto.periodoAte,
                                            value: inputDate,
                                            error: false,
                                        },
                                    },
                                }));
                                if (
                                    inputDate?.isBefore(formData.dataBaixaCanhoto.periodoDe.value)
                                ) {
                                    setFormData((prevState) => ({
                                        ...prevState,
                                        dataBaixaCanhoto: {
                                            ...prevState.dataBaixaCanhoto,
                                            periodoAte: {
                                                ...prevState.dataBaixaCanhoto.periodoAte,
                                                value: null,
                                                error: true,
                                            },
                                        },
                                    }));
                                }
                            }}
                            value={formData.dataBaixaCanhoto.periodoAte.value}
                            error={formData.dataBaixaCanhoto.periodoAte.error}
                            helperText={'Data final deve ser maior que a inicial'}
                            maxDate={dayjs(new Date())}
                        />
                    </CalendarArea>

                    <TitleInput>Data da emissão*:</TitleInput>
                    <CalendarArea>
                        <InputDate
                            label="De"
                            testId="calendar-input"
                            onChange={(inputDate, validationError) => {
                                if (
                                    !isValidDate(
                                        inputDate?.format('YYYY-MM-DD') || 'Invalid',
                                        validationError,
                                        () =>
                                            setFormData((prevState) => ({
                                                ...prevState,
                                                dataEmissaoNFe: {
                                                    ...prevState.dataEmissaoNFe,
                                                    periodoDe: {
                                                        ...prevState.dataEmissaoNFe.periodoDe,
                                                        value: inputDate,
                                                        error: false,
                                                    },
                                                },
                                            }))
                                    )
                                ) {
                                    return;
                                }
                                setFormData((prevState) => ({
                                    ...prevState,
                                    dataEmissaoNFe: {
                                        ...prevState.dataEmissaoNFe,
                                        periodoDe: {
                                            ...prevState.dataEmissaoNFe.periodoDe,
                                            value: inputDate,
                                            error: false,
                                        },
                                    },
                                }));
                                if (inputDate?.isAfter(formData.dataEmissaoNFe.periodoAte.value)) {
                                    setFormData((prevState) => ({
                                        ...prevState,
                                        dataEmissaoNFe: {
                                            ...prevState.dataEmissaoNFe,
                                            periodoDe: {
                                                ...prevState.dataEmissaoNFe.periodoDe,
                                                value: null,
                                                error: true,
                                            },
                                        },
                                    }));
                                }
                            }}
                            value={formData.dataEmissaoNFe.periodoDe.value}
                            error={formData.dataEmissaoNFe.periodoDe.error}
                            helperText={'Data inicial deve ser menor que a final'}
                            maxDate={dayjs(new Date())}
                        />
                    </CalendarArea>
                    <CalendarArea>
                        <InputDate
                            label="Até"
                            testId="calendar-input"
                            onChange={(inputDate, validationError) => {
                                if (
                                    !isValidDate(
                                        inputDate?.format('YYYY-MM-DD') || 'Invalid',
                                        validationError,
                                        () =>
                                            setFormData((prevState) => ({
                                                ...prevState,
                                                dataEmissaoNFe: {
                                                    ...prevState.dataEmissaoNFe,
                                                    periodoAte: {
                                                        ...prevState.dataEmissaoNFe.periodoAte,
                                                        value: inputDate,
                                                        error: false,
                                                    },
                                                },
                                            }))
                                    )
                                ) {
                                    return;
                                }
                                setFormData((prevState) => ({
                                    ...prevState,
                                    dataEmissaoNFe: {
                                        ...prevState.dataEmissaoNFe,
                                        periodoAte: {
                                            ...prevState.dataEmissaoNFe.periodoAte,
                                            value: inputDate,
                                            error: false,
                                        },
                                    },
                                }));
                                if (inputDate?.isBefore(formData.dataEmissaoNFe.periodoDe.value)) {
                                    setFormData((prevState) => ({
                                        ...prevState,
                                        dataEmissaoNFe: {
                                            ...prevState.dataEmissaoNFe,
                                            periodoAte: {
                                                ...prevState.dataEmissaoNFe.periodoAte,
                                                value: null,
                                                error: true,
                                            },
                                        },
                                    }));
                                }
                            }}
                            value={formData.dataEmissaoNFe.periodoAte.value}
                            error={formData.dataEmissaoNFe.periodoAte.error}
                            helperText={'Data final deve ser maior que a inicial'}
                            maxDate={dayjs(new Date())}
                        />
                    </CalendarArea>
                </ContainerDate>

                <ContainerDefaultSearch>
                    <DefautSearchArea>
                        <InputFloatingLabel
                            type="text"
                            id="Emitente (Nome, Código da Base ou CNPJ)"
                            label="Emitente (Nome, Código da Base ou CNPJ)"
                            value={formData.emitente.value}
                            onChange={(e) => {
                                let value = e.target.value;
                                value = value.replace(/[^0-9A-Za-zçÇ\s]/g, '');
                                handleNonArrayForms(value, 'emitente');
                            }}
                            error={formData.emitente.error}
                            helperText={formData.emitente.textError}
                        />
                    </DefautSearchArea>
                    <DefautSearchArea>
                        <InputFloatingLabel
                            type="text"
                            id="Número da NF-e"
                            label="Número da NF-e"
                            value={formData.numeroNF[0].value}
                            onChange={(e) => {
                                const inputValue = e.target.value;
                                if (verifyIsNumber(inputValue))
                                    setFormData((prevState: any) => ({
                                        ...prevState,
                                        numeroNF: [{ ...prevState.numeroNF[0], value: inputValue }],
                                    }));
                            }}
                            error={formData.numeroNF[0].error}
                            helperText={formData.numeroNF[0].textError}
                        />
                    </DefautSearchArea>
                </ContainerDefaultSearch>
                <ContainerDefaultSearch>
                    <DefautSearchArea>
                        <InputFloatingLabel
                            type="text"
                            id="Cliente (Nome ou CNPJ)"
                            label="Cliente (Nome ou CNPJ)"
                            value={formData.cliente.value}
                            onChange={(e) => {
                                let value = e.target.value;
                                value = value.replace(/[^0-9A-Za-zçÇ\s]/g, '');
                                handleNonArrayForms(value, 'cliente');
                            }}
                            error={formData.cliente.error}
                            helperText={formData.cliente.textError}
                        />
                    </DefautSearchArea>
                    <DefautSearchArea>
                        <InputFloatingLabel
                            type="select"
                            options={STATUS_CANHOTO}
                            id="Status canhoto"
                            label="Status canhoto"
                            value={formData.statusCanhoto.value}
                            onChange={(e) => handleNonArrayForms(e.target.value, 'statusCanhoto')}
                            error={formData.statusCanhoto.error}
                            helperText={formData.statusCanhoto.textError}
                        />
                    </DefautSearchArea>
                </ContainerDefaultSearch>

                <ContainerTitleAdvancedSearch
                    onClick={() => setShowAdvancedSearh(!showAdvancedSearh)}
                >
                    <TitleInput>Busca avançada</TitleInput>
                    {CarretIcon(showAdvancedSearh)}
                </ContainerTitleAdvancedSearch>
                {showAdvancedSearh && (
                    <ContainerAdvancedSearch>
                        <ContentAdvancedSearch style={{ justifyContent: 'space-between' }}>
                            <AdvancedSearchArea>
                                <InputFloatingLabel
                                    type="text"
                                    id="Chave da NF-e"
                                    label="Chave da NF-e"
                                    value={formData.chaveNFe[0].value}
                                    onChange={(e) => {
                                        const inputValue = e.target.value;
                                        setFormData((prevState: any) => ({
                                            ...prevState,
                                            chaveNFe: [
                                                { ...prevState.chaveNFe[0], value: inputValue },
                                            ],
                                        }));
                                    }}
                                    error={formData.chaveNFe[0].error}
                                    helperText={formData.chaveNFe[0].textError}
                                />
                            </AdvancedSearchArea>
                            <AdvancedSearchArea>
                                <InputFloatingLabel
                                    type="text"
                                    id="Nome do Transportador"
                                    label="Nome do Transportador"
                                    value={formData.nomeTransportador.value}
                                    onChange={(e) =>
                                        handleNonArrayForms(e.target.value, 'nomeTransportador')
                                    }
                                    error={formData.nomeTransportador.error}
                                    helperText={formData.nomeTransportador.textError}
                                />
                            </AdvancedSearchArea>
                            <ContainerVolume>
                                <AdvancedSearchArea style={{ width: '49%' }}>
                                    <InputFloatingLabel
                                        type="text"
                                        id="Volume de"
                                        label="Volume de"
                                        value={formData.volume.rangeDe.value}
                                        onChange={(e) => {
                                            const inputValue = e.target.value;
                                            setFormData((prevState: any) => ({
                                                ...prevState,
                                                volume: {
                                                    ...prevState.volume,
                                                    rangeDe: {
                                                        ...prevState.volume.rangeDe,
                                                        value: inputValue,
                                                    },
                                                },
                                            }));
                                        }}
                                        error={formData.volume.rangeDe.error}
                                        helperText={formData.volume.rangeDe.textError}
                                    />
                                </AdvancedSearchArea>
                                <AdvancedSearchArea style={{ width: '49%' }}>
                                    <InputFloatingLabel
                                        type="text"
                                        id="Volume até"
                                        label="Volume até"
                                        value={formData.volume.rangeAte.value}
                                        onChange={(e) => {
                                            const inputValue = e.target.value;
                                            setFormData((prevState: any) => ({
                                                ...prevState,
                                                volume: {
                                                    ...prevState.volume,
                                                    rangeAte: {
                                                        ...prevState.volume.rangeAte,
                                                        value: inputValue,
                                                    },
                                                },
                                            }));
                                        }}
                                    />
                                </AdvancedSearchArea>
                            </ContainerVolume>
                        </ContentAdvancedSearch>

                        <ContentAdvancedSearch style={{ justifyContent: 'space-between' }}>
                            <AdvancedSearchArea>
                                <InputFloatingLabel
                                    type="text"
                                    id="Série"
                                    label="Série"
                                    value={formData.serie.value}
                                    onChange={(e) => handleNonArrayForms(e.target.value, 'serie')}
                                    error={formData.serie.error}
                                />
                            </AdvancedSearchArea>
                            <AdvancedSearchArea>
                                <InputFloatingLabel
                                    type="text"
                                    id="Produto"
                                    label="Produto"
                                    value={formData.produto.value}
                                    onChange={(e) => handleNonArrayForms(e.target.value, 'produto')}
                                    error={formData.produto.error}
                                    helperText={formData.produto.textError}
                                />
                            </AdvancedSearchArea>
                            <AdvancedSearchArea>
                                <InputFloatingLabel
                                    type="text"
                                    id="CNPJ do transportador"
                                    label="CNPJ do transportador"
                                    value={formData.CNPJTransportador.value}
                                    onChange={(e) =>
                                        handleNonArrayForms(e.target.value, 'CNPJTransportador')
                                    }
                                    error={formData.CNPJTransportador.error}
                                    helperText={formData.CNPJTransportador.textError}
                                />
                            </AdvancedSearchArea>
                        </ContentAdvancedSearch>
                        <ContentAdvancedSearch>
                            <AdvancedSearchArea>
                                <InputFloatingLabel
                                    type="text"
                                    id="Numero do pedido"
                                    label="Número do pedido"
                                    value={formData.numeroPedido.value}
                                    onChange={(e) =>
                                        handleNonArrayForms(e.target.value, 'numeroPedido')
                                    }
                                    error={formData.numeroPedido.error}
                                    helperText={formData.numeroPedido.textError}
                                />
                            </AdvancedSearchArea>
                            <AdvancedSearchArea>
                                <InputFloatingLabel
                                    type="text"
                                    id="Codigo do transportador"
                                    label="Código do transportador"
                                    value={formData.codigoTransportador.value}
                                    onChange={(e) =>
                                        handleNonArrayForms(e.target.value, 'codigoTransportador')
                                    }
                                    error={formData.codigoTransportador.error}
                                    helperText={formData.codigoTransportador.textError}
                                />
                            </AdvancedSearchArea>
                        </ContentAdvancedSearch>
                    </ContainerAdvancedSearch>
                )}
                <ContainerButton>
                    <Button
                        typeStyle="outlined"
                        data-testid="button-new-version"
                        onClick={clearFormFields}
                    >
                        Limpar Filtro
                    </Button>
                    <Button
                        disabled={isLoading || disabledButtons()}
                        typeStyle="default"
                        data-testid="button-new-version"
                        onClick={() => {
                            setPage(1);
                            setListInvoicesSelected({
                                invoices: [],
                                show: false,
                                type: '',
                            } as InvoiceFiltredModal);
                            handleGetInvoicesData();
                        }}
                    >
                        {isLoading ? (
                            <div className={`${styles.rotate} ${styles.loadingButton}`} />
                        ) : (
                            'Consultar'
                        )}
                    </Button>
                </ContainerButton>
            </BorderTable>
            {listInvoices.length === 0 ? (
                <ContainerFeedbackNoResult>
                    {showFeedbackFooter && !isLoading && (
                        <>
                            <TitleInput>Resultado da pesquisa</TitleInput>
                            <IconAlert src={Alert} alt="Alert" />
                            <TitleInput style={{ alignSelf: 'center', fontSize: 28 }}>
                                Nenhum registro encontrado
                            </TitleInput>
                        </>
                    )}
                    {isLoading && <div className={`${styles.rotate} ${styles.loading}`} />}
                </ContainerFeedbackNoResult>
            ) : (
                <ContainerFeedbackNoResult>
                    <TitleInput style={{ marginBottom: 28 }}>Resultado da pesquisa</TitleInput>

                    <ListInvoicesComponent
                        isCheckBox
                        invoicesData={listInvoices}
                        headerTitles={columnsTitles}
                        isSelectAll={allInvoicesSelected}
                        setSelectAllChecked={setAllInvoicesSelected}
                        quatityPerPage={10}
                        changePage={(page: number) => {
                            setPage(page);
                            handleGetInvoicesData(10, page);
                        }}
                        quantityTotalPages={quantityTotalPages}
                        onSelect={(invoice) => {
                            onSelectInvoice(invoice);
                        }}
                        onSelectAll={(selectAll) => {
                            if (selectAll) {
                                setListInvoicesSelected({
                                    invoices: listInvoices,
                                    show: false,
                                    type: '',
                                });
                            } else {
                                setListInvoicesSelected({
                                    invoices: [],
                                    show: false,
                                    type: '',
                                });
                            }
                            setAllInvoicesSelected(selectAll);
                        }}
                        selectedListItem={listInvoicesSelected.invoices}
                        onExportPDF={onShowModalPdf}
                        onViewInvoice={onShowModalView}
                        onExportCSV={onShowModalCsv}
                        quantityAll={quantityInvoices}
                        page={page}
                        loading={isLoadingImages}
                        keyExportButton={listInvoicesSelected.invoices.length.toString()}
                    />

                    {isLoading && (
                        <div className={styles.loaderTable}>
                            <div className={`${styles.rotate} ${styles.loading}`} />
                        </div>
                    )}
                </ContainerFeedbackNoResult>
            )}

            <ExportCVSANDPDFModal
                showFeedback={showFeedback}
                clearSelected={() => {
                    setAllInvoicesSelected(false);
                    setListInvoicesSelected({
                        invoices: [],
                        show: false,
                        type: '',
                    } as InvoiceFiltredModal);
                }}
                headerContent={'Confirmação dos canhotos selecionandos'}
                show={listInvoicesSelected.show && listInvoicesSelected.type !== 'view'}
                notas={listInvoicesSelected.invoices}
                handleClose={() => {
                    if (!allInvoicesSelected && listInvoicesSelected.invoices.length > 10) {
                        setListInvoicesSelected({
                            invoices: [],
                            show: false,
                            type: '',
                        } as InvoiceFiltredModal);
                    } else setListInvoicesSelected({ ...listInvoicesSelected, show: false });
                }}
                quantityInvoices={quantityInvoices}
                isPdf={listInvoicesSelected.type === 'pdf'}
            />

            <ExportPDFModal
                showFeedback={showFeedback}
                show={listInvoicesSelected.show && listInvoicesSelected.type === 'view'}
                handleClose={() =>
                    setListInvoicesSelected({ ...listInvoicesSelected, show: false })
                }
                notas={listInvoicesSelected.invoices}
                clearSelected={() => {
                    setAllInvoicesSelected(false);
                    setListInvoicesSelected({
                        invoices: [],
                        show: false,
                        type: '',
                    } as InvoiceFiltredModal);
                }}
            />
        </Container>
    );
};

export default Invoices;
