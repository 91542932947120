import FeatureCard from "components/FeatureCard";
import FeatureFlagIcon from "../../assets/feature-flag-icon.svg";
import InvoiceIcon from "../../assets/invoice-icon.svg";
import VersionIcon from "../../assets/version-icon.svg";
import styles from "./styles.module.scss";

const Home = () => {
  return (
    <div
      className={`w-100 h-100 ${styles.container}`}
      data-testid="container-page"
    >
      <div className={styles.row}>
        <FeatureCard
          onClick={() => {
            window.location.href = "/gestao-funcionalidades";
          }}
          title="Gestão de funcionalidades"
          color="#0045B5"
          backgroundColor="#BCD6F0"
          img={FeatureFlagIcon}
        />
        <FeatureCard
          onClick={() => {
            window.location.href = "/versionamento";
          }}
          title="Versionamento"
          color="#58595B"
          backgroundColor="#FFD100"
          img={VersionIcon}
        />
        <FeatureCard
          onClick={() => {
            window.location.href = "/canhoto-digital";
          }}
          title="Canhoto Digital"
          color="#FF6900"
          backgroundColor="#FFF5CC"
          img={InvoiceIcon}
        />
      </div>
    </div>
  );
};

export default Home;
