import Modal from 'react-bootstrap/Modal';
import styles from './styles.module.scss';
// import InvoicePDFModal from "components/InvoicePDFModal";
import InvoicePDFModal from 'components/InvoicePDFModal';
import ListInvoicesComponent from 'components/ListInvoicesComponent';
import LoadingCircle from 'components/LoadingCircle';
import { columnsTitles } from 'pages/Invoices/types';
import { useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import { generateZipPdf, invoicesToCsv, nameFileCSV } from 'utils/basic/invoices';
import Button from '../Button';
import { InvoicesBack } from './types';

interface ExportCVSModalProps {
    show: boolean;
    handleClose?: () => void;
    headerContent?: string;
    onSubmit?: () => void;
    hasCloseButton?: boolean;
    saveButtonText?: string;
    cancelButtonText?: string;
    testId?: string;
    loading?: boolean;
    notas: InvoicesBack['notas'];
    quantityInvoices: number;
    isPdf: boolean;
    showFeedback: () => void;
    clearSelected: () => void;
}

export default function ExportCVSANDPDFModal({
    show = false,
    handleClose = () => {},
    hasCloseButton = true,
    testId,
    notas,
    loading,
    quantityInvoices,
    isPdf,
    showFeedback,
    clearSelected,
}: ExportCVSModalProps) {
    const [notasFiltered, setNotasFiltered] = useState([] as any);
    const [csvData, setCsvData] = useState([] as any);
    const [isLoadingExportPdf, setIsLoadingExportPdf] = useState(false);

    useEffect(() => {
        setNotasFiltered(notas);
        setCsvData(invoicesToCsv(notas));
    }, [notas]);

    const handleRemoveInvoice = (numeroNF: any) => {
        setNotasFiltered(notasFiltered.filter((item: any) => item.numeroNF !== numeroNF));

        setCsvData(csvData.filter((row: string[]) => !row.includes(numeroNF)));
    };

    return (
        <Modal data-testid={testId} show={show} onHide={handleClose} size="xl">
            <Modal.Header closeButton={!!hasCloseButton} style={{ border: 'none' }}>
                <Modal.Title className={styles.title}>
                    Confirmação dos canhotos selecionados
                </Modal.Title>
            </Modal.Header>
            <Modal.Body
                style={{
                    maxHeight: 'calc(100vh - 200px)',
                    overflow: 'auto',
                }}
            >
                <ListInvoicesComponent
                    invoicesData={notasFiltered}
                    headerTitles={columnsTitles}
                    quatityPerPage={quantityInvoices}
                    quantityAll={quantityInvoices}
                    page={1}
                    handleRemoveInvoice={(numeroNF: any) => handleRemoveInvoice(numeroNF)}
                    loading={false}
                />
            </Modal.Body>
            <Modal.Footer style={{ border: 'none' }}>
                <div className={`${styles.actionsButtons} `}>
                    {!isLoadingExportPdf ? (
                        <>
                            <Button
                                classColorButton={styles.textPDF}
                                customClassName={styles.buttonPDF}
                                disabled={loading}
                                onClick={async () => {
                                    if (isPdf) {
                                        setIsLoadingExportPdf(true);
                                        generateZipPdf({
                                            notas: notasFiltered,
                                            component: notasFiltered.map((nota: any) => (
                                                <InvoicePDFModal notas={[nota]} />
                                            )),
                                            finishLoad: () => {
                                                setIsLoadingExportPdf(false);
                                                showFeedback();
                                                handleClose();
                                                clearSelected();
                                            },
                                        });
                                    }
                                }}
                            >
                                {isPdf ? (
                                    <span>Confirmar</span>
                                ) : (
                                    <CSVLink
                                        filename={nameFileCSV + '.csv'}
                                        className={styles.csvLink}
                                        target="_blank"
                                        data={csvData}
                                        onClick={(e: any) => {
                                            e.stopPropagation();
                                            handleClose();
                                            clearSelected();
                                            showFeedback();
                                        }}
                                    >
                                        Confirmar
                                    </CSVLink>
                                )}
                            </Button>
                            <Button
                                customClassName={`${styles.cancelButton}`}
                                isLoading={loading}
                                onClick={() => {
                                    handleClose();
                                }}
                                disabled={loading}
                            >
                                <span className={styles.cancelText}>Cancelar</span>
                            </Button>
                        </>
                    ) : (
                        <LoadingCircle />
                    )}
                </div>
            </Modal.Footer>
        </Modal>
    );
}
