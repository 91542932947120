import { Fragment } from "react";
import { useLocation } from "react-router";

import { Link } from "react-router-dom";

import styles from "./styles.module.scss";
import { BREADCRUMBS } from "../../utils/constants/breadcrumbs";

const BreadCrumbs = () => {
  const { pathname = "" } = useLocation();

  const splitRoute = (str: any) => {
    let nextString = str;
    if (str?.length)
      nextString =
        str?.split()?.reverse()[0] === "/"
          ? str?.substr(0, str.length - 1)
          : str;
    return nextString?.split("/")?.map((item: any) => item.trim()) || [];
  };

  const compareRoute = (route: any) => {
    if (route === pathname) return true;
    
    const splRoute = splitRoute(route);
    const splPath = splitRoute(pathname);

    if (splRoute.length !== splPath.length) return false;

    let isEqual = false;
    for (let i = 0; i < splRoute.length; i += 1) {
      isEqual = !!(
        splRoute[i] === splPath[i] ||
        (splRoute[i] !== splPath[i] && !!splRoute[i].match(/^:/))
      );
      if (!isEqual) break;
    }
    return isEqual;
  };

  return (
    <>
      {BREADCRUMBS.filter((crumb) => compareRoute(crumb.route)).map(
        (crumb, crumbIdx) => (
          <Fragment key={crumbIdx}>
            <div key={crumbIdx} className="d-flex">
              {crumb.parents &&
                crumb.parents?.map((parent, parentIdx) => (
                    <div key={parentIdx} className={styles.routeArea}>
                      <Link className={`${styles['text-crumb']}`} to={parent.route}>
                        {parent.title}
                      </Link>
                      <span className={`navbar-text px-2 ${styles['text-crumb']}`}>/</span>
                    </div>
                ))}
              <span className={`navbar-text ${styles['text-crumb']}`}>{crumb.title}</span>
            </div>
          </Fragment>
        )
      )}
    </>
  );
};

export default BreadCrumbs;
