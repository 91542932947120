import styled, { css } from 'styled-components'

interface PropsStylePagination {
    selected?: any
}
interface PropsStyleListInvoices {
    isChecked: boolean
}

export const Table = styled.table`
    width: 100%;
    border-collapse: collapse;
`

export const TableHeader = styled.thead`
    background-color: ${({ theme }) => theme.colors.white};
    
`

export const TableHeaderRow = styled.tr`
    background-color: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.blue_ipiranga};
`

export const TableRow = styled.tr`
    border-top: 2px solid ${({ theme }) => theme.colors.blue_tertiary};
    border-bottom: 2px solid ${({ theme }) => theme.colors.blue_tertiary};
    
`

export const TableCell = styled.td`
    padding: 8px;
    text-align: center;
`
export const TextTitle = styled.span`
    color: ${({ theme }) => theme.colors.blue_text};
    font-weight: bold;
`
export const TextList = styled.span<PropsStyleListInvoices>`
    color: ${(props) =>
        props.isChecked ? props.theme.colors.blue_tertiary : props.theme.colors.blue_text};
    font-weight: bold;
`

export const TextPagination = styled.span<PropsStylePagination>`
    ${(props) =>
        props.selected &&
        css`
            font-weight: bold;
        `}
    color: ${({ theme }) => theme.colors.blue_text};
`

export const CheckboxCell = styled.input`
    margin-right: 5px;
`
export const ContainerPagination = styled.div`
    flex-direction: row;
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 16px;
`

export const PaginationButton = styled.button`
    background-color: ${({ theme }) => theme.colors.white};
    border: 0px solid ${({ theme }) => theme.colors.white};
    margin-bottom: 22px;
`

export const Separate = styled.div`
    height: 1px;
    background-color: ${({ theme }) => theme.colors.grey_2};
    margin: 8px 0;
`

export const ContainerButton = styled.div`
    display: flex;
    width: 100%;
    justify-content: flex-end;
`
export const LoadingView = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 5%;
`