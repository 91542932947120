import { pdf } from '@react-pdf/renderer';
import { InvoicesBack } from 'components/ExportPDFModal/types';
import { saveAs } from 'file-saver';
import JSZip from 'jszip';

const token = localStorage.getItem('token');

const tokenParse = token ? JSON.parse(token) : null;

export const nameFileCSV = 'CanhotoDigital' + new Date().toLocaleDateString();

export const titleCSV = () => `CanhotoDigital - Exportação realizada em ${new Date().toLocaleString(
  'pt-BR',
  { year: 'numeric', month: '2-digit', day: '2-digit' },
)} às ${new Date().toLocaleString('pt-BR', {
  hour: '2-digit',
  minute: '2-digit',
})}`;

const UserLogged =
  tokenParse && tokenParse.idTokenClaims && tokenParse.idTokenClaims.name;

export const nameFilePDF = (
  destinatario: string,
  dataEmissao: string,
  noNf: string,
) => 'CanhotoDigital-' + destinatario + '-' + new Date(dataEmissao).toLocaleDateString("pt-BR").replaceAll("/", "-") + '-' + noNf;

export const generateZipPdf = async ({ notas, component, finishLoad }: any) => {
  if (notas.length === 1) {
    const doc = component[0];
    const asPdf = pdf([doc] as any);
    const blob = await asPdf.toBlob();
    saveAs(
      blob,
      nameFilePDF(
        notas[0].cliente.nome.replaceAll('/', '-'),
        notas[0].dataEmissaoNFe,
        notas[0].numeroNF,
      ) + '.pdf',
    );
    finishLoad();
    return;
  }

  const zip = new JSZip();
  let docs = [];
  const pdfs = zip.folder('CanhotoDigital' + new Date().toLocaleDateString().replaceAll("/", "-"));
  let i = 0;
  for (const item of notas) {
    const doc = component[i];
    const asPdf = pdf([] as any);
    asPdf.updateContainer(doc);
    const blob = await asPdf.toBlob();
    docs.push(blob);
    pdfs?.file(
      nameFilePDF(
        item.cliente.nome.replaceAll('/', '-'),
        item.dataEmissaoNFe,
        item.numeroNF,
      ) + '.pdf',
      blob,
      {},
    );
    i++;
  }
  zip.generateAsync({ type: 'blob' }).then(function (content) {
    saveAs(content, 'CanhotoDigital' + new Date().toLocaleDateString() + '.zip');
  });
  finishLoad();
};

export function invoicesToCsv(data: InvoicesBack['notas']) {
  const csvData: string[][] = [
    [
      'Título',
      'Usuário',
      'emitente_codigo',
      'emitente_cnpj',
      'emitente_nome',
      'cliente_nome',
      'cliente_cnpj',
      'endereco_cliente',
      'dataEmissaoNFe',
      'dataBaixaCanhoto',
      'modalidadeFrete',
      'statusCanhoto',
      'numeroNF',
      'serie',
      'chaveNFe',
      'numeroPedido',
      'produto_id',
      'produto_nome',
      'produto_volume',
      'codigoTransportador',
      'cnpjTransportador',
      'nomeTransportador',
    ],
  ];

  data.forEach((nota) => {
    nota.produtos.forEach(produto => {
      const productRow = [
        titleCSV(),
        UserLogged,
        nota.emitente.codigo,
        nota.emitente.cnpj,
        nota.emitente.nome,
        nota.cliente.nome,
        nota.cliente.cnpj,
        nota.cliente.endereco,
        nota.dataEmissaoNFe,
        nota.dataBaixaCanhoto === 'null' ? 'Sem registro' : nota.dataBaixaCanhoto,
        ["1", "2", "4"].includes(nota.modalidadeFrete) ? 'FOB' : 'CIF',
        nota.statusCanhoto,
        nota.numeroNF,
        nota.serie,
        nota.chaveNFe,
        nota.numeroPedido,
        produto.id,
        produto.nome,
        produto.volume,
        nota.codigoTransportador,
        nota.cnpjTransportador,
        nota.nomeTransportador,
      ];
      csvData.push(productRow);
    });
  });

  return csvData;
}
