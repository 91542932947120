import Button from "../../components/Button";
import styles from "./styles.module.scss";

interface SaveCancelButtonsProps {
  areaClassName?: string;
  cancelFunc: () => void;
  saveFunc: () => void;
  saveButton?: string;
  saveButtonAlternativeText?: string;
  cancelButtonAlternativeText?: string;
  loading?: boolean;
}

const SaveCancelButtons = ({
  areaClassName = "",
  cancelFunc,
  saveFunc,
  saveButton = "",
  saveButtonAlternativeText = "",
  cancelButtonAlternativeText = "",
  loading,
}: SaveCancelButtonsProps) => {
  return (
    <div className={`${styles.actionsButtons} ${areaClassName}`}>
      <Button
        classColorButton={styles.cancelText}
        customClassName={styles.cancelButton}
        onClick={cancelFunc}
        disabled={loading}
      >
        <span>{cancelButtonAlternativeText || "Cancelar"}</span>
      </Button>
      <Button
        customClassName={`${styles.saveButton} ${saveButton}`}
        classColorButton={styles.saveText}
        onClick={saveFunc}
        isLoading={loading}
        disabled={loading}
      >
        <span>{ saveButtonAlternativeText || "Salvar" }</span>
      </Button>
    </div>
  );
};

export default SaveCancelButtons;
