import axios from 'axios';

const baseURL =
    process.env.REACT_APP_BASE_URL ?? 'https://portalmotorista.hml.apis.ipiranga.io/v1/web';

const api = axios.create({
    baseURL: baseURL,
    timeout: 30000,
});

api.interceptors.request.use(
    async (config) => {
        const configNew = { ...config };

        const token = localStorage.getItem('token');

        if (token) {
            const tokenParse = JSON.parse(token);
            configNew.headers.Authorization = `Bearer ${tokenParse.accessToken}`;
        }

        return { ...configNew };
    },
    (error) => {
        Promise.reject(error);
    }
);

api.interceptors.response.use(
    (response) => {
        return response;
    },
    async (error) => {
        return Promise.reject(error);
    }
);

export default api;
