import { useCallback, useRef } from 'react';
import { Table } from 'reactstrap';

import { useEffect, useState } from 'react';
import { useAppDispatch } from '../../App/hooks';
import { setStateFeatureFlagSelected } from '../../Feature/featureFlag';
import { EditFeatureFlag, getFeatureFlagData } from '../../Functions';
import DownArrow from '../../assets/down-arrow.svg';
import UpArrow from '../../assets/up-arrow.svg';
import SearchInput from '../../components/SearchInput';
import styles from './styles.module.scss';

import { TableDataProps } from 'Functions/featureFlag/types';
import { Form } from 'react-bootstrap';
import { renderToast } from 'utils/basic/renderToast';
import ConfirmationModal from '../../components/ConfirmationModal';
import SaveCancelButtons from '../../components/SaveCancelButtons';
import {
    EditCheckboxItemProps,
    FillAllFieldsProps,
    TableColumn,
    columnKeyToColType,
} from './types';

const FeatureFlag = () => {
    const [initialDataTb, setInitialDatatb] = useState<TableDataProps[]>([]);
    const [dataTb, setDatatb] = useState<TableDataProps[]>([]);
    const [searchedTb, setSearchedTb] = useState<TableDataProps[]>([]);
    const [sortCod, setSortCod] = useState<undefined | boolean>(undefined);

    const [showConfirmChangeBaseModal, setShowConfirmChangeBaseModal] = useState<boolean>(false);

    const [sortLoadBase, setSortLoadBase] = useState<undefined | boolean>(undefined);
    const [textSearch, setTextSearch] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isLoadingPage, setIsLoadingPage] = useState<boolean>(false);
    const [selectAllInvoicesCIF, setSelectAllInvoicesCIF] = useState<0 | 1 | 2>(0);
    const [selectAllInvoicesFOB, setSelectAllInvoicesFOB] = useState<0 | 1 | 2>(0);
    const [selectAllScheduling, setSelectAllScheduling] = useState<0 | 1 | 2>(0);
    const [selectAllNextTrips, setSelectAllNextTrips] = useState<0 | 1 | 2>(0);
    const [selectAllBillingCIF, setSelectAllBillingCIF] = useState<0 | 1 | 2>(0);
    const [selectAllBillingFOB, setSelectAllBillingFOB] = useState<0 | 1 | 2>(0);
    const [selectedColumns, setSelectedColumns] = useState({
        invoicesCIF: false,
        invoicesFOB: false,
        billingCIF: false,
        billingFOB: false,
        scheduling: false,
        nextTrips: false,
    });

    const initialStateColumns = useRef(selectedColumns);

    const handleSelectAllStateChange = (columnKey: string, newState: boolean) => {
        const newData = dataTb.map((item) => ({
            ...item,
            row: { ...item.row, [columnKey]: newState },
        }));
        if (textSearch) {
            setSearchedTb((prev) =>
                prev.map((item) => ({
                    ...item,
                    row: { ...item.row, [columnKey]: newState },
                }))
            );
        }
        setDatatb(newData);

        setSelectedColumns((prev) => ({ ...prev, [columnKey]: newState }));
    };

    const editCheckboxItem = ({ cod, col }: EditCheckboxItemProps) => {
        const newData: TableDataProps[] = [];
        const newDataSearch: TableDataProps[] = [];

        dataTb.forEach((item) => {
            const row = item.row;
            if (row.cod === cod) {
                const newRow = { ...row, [col]: !row[col] };
                newData.push({ row: newRow });
            } else {
                newData.push(item);
            }
        });
        searchedTb.forEach((item) => {
            const row = item.row;
            if (row.cod === cod) {
                const newRow = { ...row, [col]: !row[col] };
                newDataSearch.push({ row: newRow });
            } else {
                newDataSearch.push(item);
            }
        });
        setDatatb(newData);

        setSearchedTb(newDataSearch);

        const isSelected = newData.some((item) => item.row[col]);
        setSelectedColumns((prev) => ({ ...prev, [col]: isSelected }));
    };

    const sortByColumn = (
        sort: boolean | undefined,
        col: 'cod' | 'loadBase',
        setFunction: (e: any) => void
    ) => {
        const array = [...dataTb];
        const sortedData = array.sort((a, b) => {
            if (sort) {
                return a.row[col] > b.row[col] ? 1 : -1;
            } else {
                return a.row[col] < b.row[col] ? 1 : -1;
            }
        });

        setFunction(!sort);
        setDatatb(sortedData);
    };

    const sortCaret = (sortValue: boolean | undefined) => (
        <img src={sortValue ? UpArrow : DownArrow} alt="setas" />
    );

    const fillAllFields = ({ col, conditionToFillIn }: FillAllFieldsProps) => {
        const newData: TableDataProps[] = [];

        dataTb.forEach((item) => {
            const row = item.row;
            const newRow = { ...row, [col]: conditionToFillIn };
            newData.push({ row: newRow });
        });
        if (textSearch) {
            const newDataSearched: TableDataProps[] = [];
            searchedTb.forEach((item) => {
                const row = item.row;
                const newRow = { ...row, [col]: conditionToFillIn };
                newDataSearched.push({ row: newRow });
            });
            setSearchedTb(newDataSearched);
        }

        setDatatb(newData);
    };

    const sortList = (list: TableDataProps[]) =>
        list
            .slice()
            .sort(
                (a: TableDataProps, b: TableDataProps) => parseInt(a.row.cod) - parseInt(b.row.cod)
            );

    const verifyIfListsAreEquals = (array1: TableDataProps[], array2: TableDataProps[]) => {
        if (array1.length !== array2.length) {
            return false;
        }

        const sortedArray1 = sortList(array1);
        const sortedArray2 = sortList(array2);

        for (let i = 0; i < sortedArray1.length; i++) {
            if (sortedArray1[i] !== sortedArray2[i]) {
                return false;
            }
        }

        return true;
    };

    const hasModificationData = () => !verifyIfListsAreEquals(initialDataTb, dataTb);

    const onConfirmChangeBase = async () => {
        setShowConfirmChangeBaseModal(false);
        setIsLoadingPage(true);

        try {
            await EditFeatureFlag(dataTb, initialDataTb, {
                billingCIF: selectAllBillingCIF,
                billingFOB: selectAllBillingFOB,
                invoicesCIF: selectAllInvoicesCIF,
                invoicesFOB: selectAllInvoicesFOB,
                nextTrips: selectAllNextTrips,
                scheduling: selectAllScheduling,
                usuario: 'usuarios',
            });
            renderToast('Edição feita com sucesso.', { type: 'success' });
        } catch (error) {
            renderToast(
                'Desculpe, estamos com problema técnico. Por favor, aguarde alguns minutos e tente novamente.',
                { type: 'error', duration: 3000 }
            );
        }

        setIsLoadingPage(false);
        await getListFeatureFlag();
    };

    const handleCloseChangeBase = () => {
        setDatatb(initialDataTb);
        setSelectedColumns(initialStateColumns.current);
        setShowConfirmChangeBaseModal(false);
    };

    const dispatch = useAppDispatch();

    const getListFeatureFlag = useCallback(async () => {
        setIsLoading(true);

        try {
            const { tableData, functionalities } = await getFeatureFlagData();

            const mapFlags = {
                1: 'scheduling',
                2: 'invoicesCIF',
                3: 'billingFOB',
                5: 'invoicesFOB',
                4: 'nextTrips',
                6: 'billingCIF',
            };

            const flagsFound = {
                invoicesCIF: false,
                invoicesFOB: false,
                billingCIF: false,
                billingFOB: false,
                scheduling: false,
                nextTrips: false,
            };
            if (tableData) {
                functionalities.forEach((func) => {
                    flagsFound[mapFlags[func.id as 1] as 'invoicesCIF'] = func.status !== 0;
                });

                setSelectedColumns(flagsFound);
                initialStateColumns.current = flagsFound;
                setInitialDatatb(tableData);
                setDatatb(tableData);

                dispatch(setStateFeatureFlagSelected(tableData));
            }
        } catch (error) {
        } finally {
            setIsLoading(false);
        }
    }, [dispatch]);

    const tableColumns: TableColumn[] = [
        {
            name: 'Código',
            key: 'cod',
            sortable: true,
            sortKey: 'cod',
            sortFunction: setSortCod,
            sortValue: sortCod,
        },
        {
            name: 'Base',
            key: 'loadBase',
            sortable: true,
            sortKey: 'loadBase',
            sortFunction: setSortLoadBase,
            sortValue: sortLoadBase,
        },
        {
            name: 'Canhoto CIF',
            key: 'invoicesCIF',
            type: 'checkbox',
            onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                fillAllFields({
                    col: 'invoicesCIF',
                    conditionToFillIn: selectAllBillingCIF === 1 ? false : true,
                });
            },
            checked: selectAllBillingCIF === 1,
        },
        {
            name: 'Canhoto FOB',
            key: 'invoicesFOB',
            type: 'checkbox',
            onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                fillAllFields({
                    col: 'invoicesFOB',
                    conditionToFillIn: selectAllBillingFOB === 1 ? false : true,
                });
            },
            checked: selectAllBillingFOB === 1,
        },
        {
            name: 'Faturamento CIF',
            key: 'billingCIF',
            type: 'checkbox',
            onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                fillAllFields({
                    col: 'billingCIF',
                    conditionToFillIn: selectAllBillingCIF === 1 ? false : true,
                });
            },
            checked: selectAllBillingCIF === 1,
        },
        {
            name: 'Faturamento FOB',
            key: 'billingFOB',
            type: 'checkbox',
            onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                fillAllFields({
                    col: 'billingFOB',
                    conditionToFillIn: selectAllBillingFOB === 1 ? false : true,
                });
            },
            checked: selectAllBillingFOB === 1,
        },
        {
            name: 'Reagendamento',
            key: 'scheduling',
            type: 'checkbox',
            onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                fillAllFields({
                    col: 'scheduling',
                    conditionToFillIn: selectAllScheduling === 1 ? false : true,
                });
            },
            checked: selectAllScheduling === 1,
        },
        {
            name: 'Próximas Viagens',
            key: 'nextTrips',
            type: 'checkbox',
            onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                fillAllFields({
                    col: 'nextTrips',
                    conditionToFillIn: selectAllNextTrips === 1 ? false : true,
                });
            },
            checked: selectAllNextTrips === 1,
        },
    ];

    const renderTableHeaders = () => (
        <thead data-testid="table-head">
            <tr>
                {tableColumns.map((column, index) => (
                    <th
                        key={column.key}
                        data-testid="table-header-column"
                        onClick={() =>
                            column.key === 'cod'
                                ? sortByColumn(sortCod, 'cod', setSortCod)
                                : column.key === 'loadBase'
                                ? sortByColumn(sortLoadBase, 'loadBase', setSortLoadBase)
                                : null
                        }
                    >
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <span>{column.name}</span>
                            {index >= 2 && column.type === 'checkbox' && (
                                <Form.Check
                                    className={styles.checkboxHeaders}
                                    checked={
                                        selectedColumns[column.key as keyof typeof selectedColumns]
                                    }
                                    onChange={(e) => {
                                        const conditionToFillIn = e.target.checked;
                                        handleSelectAllStateChange(column.key, conditionToFillIn);
                                    }}
                                />
                            )}
                            {column.sortable &&
                                (column.key === 'cod'
                                    ? sortCaret(sortCod)
                                    : column.key === 'loadBase'
                                    ? sortCaret(sortLoadBase)
                                    : null)}
                        </div>
                    </th>
                ))}
            </tr>
        </thead>
    );
    const renderTableRows = (dataTb: TableDataProps[]) => (
        <tbody data-testid="table-body">
            {!textSearch
                ? dataTb?.map(({ row }, index: number) => (
                      <tr key={`${row.cod}_${index}`} data-testid="table-row">
                          {tableColumns.map((column, index) => (
                              <td key={column.key}>
                                  {index < 2 ? (
                                      (row[column.key] as string) ?? '----'
                                  ) : column.type === 'checkbox' ? (
                                      <Form.Check
                                          data-testid="table-header-checkbox"
                                          className={styles.checkbox}
                                          checked={(row as any)[column.key]}
                                          onChange={(e) => {
                                              editCheckboxItem({
                                                  cod: row.cod,
                                                  col: columnKeyToColType[column.key],
                                              });
                                          }}
                                      />
                                  ) : null}
                              </td>
                          ))}
                      </tr>
                  ))
                : searchedTb.map(({ row }, index: number) => (
                      <tr key={`${row.cod}_${index}`} data-testid="table-row">
                          {tableColumns.map((column, index) => (
                              <td key={column.key}>
                                  {index < 2 ? (
                                      (row[column.key] as string) ?? '----'
                                  ) : column.type === 'checkbox' ? (
                                      <Form.Check
                                          data-testid="table-header-checkbox"
                                          className={styles.checkbox}
                                          checked={(row as any)[column.key]}
                                          onChange={(e) => {
                                              editCheckboxItem({
                                                  cod: row.cod,
                                                  col: columnKeyToColType[column.key],
                                              });
                                          }}
                                      />
                                  ) : null}
                              </td>
                          ))}
                      </tr>
                  ))}
        </tbody>
    );

    useEffect(() => {
        let lengthInvoicesCIFActive = 0;
        let lengthInvoicesFOBActive = 0;
        let lengthBillingCIFActive = 0;
        let lengthBillingFOBActive = 0;
        let lengthSchedulingActive = 0;
        let lengthNextTripsActive = 0;

        dataTb.forEach((item) => {
            if (item.row.invoicesCIF) {
                lengthInvoicesCIFActive++;
            }
            if (item.row.invoicesFOB) {
                lengthInvoicesFOBActive++;
            }
            if (item.row.billingCIF) {
                lengthBillingCIFActive++;
            }
            if (item.row.billingFOB) {
                lengthBillingFOBActive++;
            }
            if (item.row.scheduling) {
                lengthSchedulingActive++;
            }
            if (item.row.nextTrips) {
                lengthNextTripsActive++;
            }
        });

        setSelectAllInvoicesCIF(
            lengthInvoicesCIFActive === dataTb.length ? 1 : lengthInvoicesCIFActive === 0 ? 0 : 2
        );
        setSelectAllInvoicesFOB(
            lengthInvoicesFOBActive === dataTb.length ? 1 : lengthInvoicesFOBActive === 0 ? 0 : 2
        );
        setSelectAllBillingCIF(
            lengthBillingCIFActive === dataTb.length ? 1 : lengthBillingCIFActive === 0 ? 0 : 2
        );
        setSelectAllBillingFOB(
            lengthBillingFOBActive === dataTb.length ? 1 : lengthBillingFOBActive === 0 ? 0 : 2
        );
        setSelectAllScheduling(
            lengthSchedulingActive === dataTb.length ? 1 : lengthSchedulingActive === 0 ? 0 : 2
        );
        setSelectAllNextTrips(
            lengthNextTripsActive === dataTb.length ? 1 : lengthNextTripsActive === 0 ? 0 : 2
        );
    }, [dataTb]);

    useEffect(() => {
        getListFeatureFlag();
    }, [getListFeatureFlag]);

    const onSearch = (e: any) => {
        const value = e.target.value;
        setTextSearch(value);
        if (value.length > 0) {
            const filteredData = dataTb.filter(
                (item) =>
                    item.row.cod.toString().includes(value) ||
                    item.row.loadBase.includes(value.toUpperCase())
            );
            setSearchedTb(filteredData);
        } else {
            setSearchedTb([]);
        }
    };

    return (
        <div className={`w-100 h-100 ${styles.container}`} data-testid="container-page">
            <div className={styles.borderTable} data-testid="border-table">
                <div className={styles.contentHeader} data-testid="content-header">
                    <SearchInput
                        testId="search-base-input"
                        placeholder="Pesquisar por código ou base de carregamento"
                        onChange={onSearch}
                        value={textSearch}
                    />
                </div>

                {isLoadingPage && (
                    <div className={styles.loadingPage} data-testid="loading-page">
                        <div className={`${styles.rotate} ${styles.loading}`} />
                    </div>
                )}

                {isLoading && <div className={`${styles.rotate} ${styles.loading}`} />}
                {!isLoading && (
                    <Table data-testid="table">
                        {renderTableHeaders()}
                        {renderTableRows(dataTb)}
                    </Table>
                )}
            </div>
            {hasModificationData() && (
                <div className={styles.footer} data-testid="footer">
                    <SaveCancelButtons
                        saveFunc={() => setShowConfirmChangeBaseModal(true)}
                        cancelFunc={handleCloseChangeBase}
                    />
                </div>
            )}

            <ConfirmationModal
                testId="save-changes-base-modal"
                headerContent="Você deseja salvar a edição das funcionalidades?"
                onSubmit={() => onConfirmChangeBase()}
                show={showConfirmChangeBaseModal}
                handleClose={handleCloseChangeBase}
                hasCloseButton
                loading={isLoadingPage}
                saveButtonText="Sim"
                cancelButtonText="Não"
            />
        </div>
    );
};

export default FeatureFlag;
