import { type PropsWithChildren } from "react";

import { StyledButton, StyledSpan } from './styles';
import { ButtonProps } from './types';



const ButtonComponent = ({ children, typeStyle = "default", ...rest }: PropsWithChildren<ButtonProps>) => {
    return (
        <StyledButton {...rest} typeStyle={typeStyle}>
            <StyledSpan>{children}</StyledSpan>
        </StyledButton>
    )
}

export default ButtonComponent
