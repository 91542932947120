import Modal from 'react-bootstrap/Modal';
import styles from './styles.module.scss';
import { QRCode } from 'react-qrcode-logo';
import { useCallback, useRef, useState } from 'react';
import InputFloatingLabel from 'components/InputFloatingLabel';
import { PDFDownloadLink } from '@react-pdf/renderer';
import QRCodePDF from 'components/QRCodePDF';
import Button from 'components/Button';

interface QRCodeModalProps {
    show: boolean;
    handleClose?: () => void;
}

export default function QRCodeModal({ show = false, handleClose = () => {} }: QRCodeModalProps) {
    const [codePrintScanner, setCodePrintScanner] = useState<string>('');
    const [qrCodeImage, setQrCodeImage] = useState<string>('');

    const qrCodeRef = useRef<any>(null);
    const generateQRCode = async () => {
        download();
    };

    const download = useCallback(() => {
        var canvas = document.getElementById('QR-CODE-FAT') as HTMLCanvasElement;
        if (!canvas) {
            return;
        }

        var link = document.createElement('a');
        link.download = 'qrcode.png';
        link.href = canvas.toDataURL('image/png');

        setQrCodeImage(link.href.replace('image/webp', 'image/octet-stream'));
    }, []);

    const onClose = () => {
        setCodePrintScanner('');
        setQrCodeImage('');
        handleClose();
    };

    return (
        <Modal
            data-testid="modal-qrcode"
            className={`${styles.modal} `}
            show={show}
            onHide={onClose}
            size="xl"
        >
            <Modal.Header className={styles.header} style={{ border: 'none' }}>
                <Modal.Title className={styles.title}>Gerar QRCode Para Faturamento</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <InputFloatingLabel
                    type="text"
                    id="versao"
                    label="Gerar QRCode"
                    value={codePrintScanner}
                    onChange={(e) => {
                        let value = e.target.value;
                        setCodePrintScanner(value);
                    }}
                />
                <div className={styles.qrcode}>
                    <QRCode
                        enableCORS={true}
                        ref={qrCodeRef}
                        id="QR-CODE-FAT"
                        value={codePrintScanner}
                        logoImage="https://pbs.twimg.com/profile_images/1633505846981283841/KeJ1rMhg_400x400.jpg"
                        bgColor="#ffcf00"
                        size={256}
                        fgColor="#0047bd"
                    />
                </div>
            </Modal.Body>

            <Modal.Footer style={{ border: 'none' }} className={styles.footer}>
                <Button
                    customClassName={styles.buttonClose}
                    classColorButton={styles.closeTextButton}
                    onClick={onClose}
                >
                    <span>cancelar</span>
                </Button>
                {!qrCodeImage && (
                    <Button
                        customClassName={styles.button}
                        classColorButton={styles.saveText}
                        onClick={() => {
                            generateQRCode();
                        }}
                    >
                        <span>Gerar PDF</span>
                    </Button>
                )}
                {qrCodeImage && (
                    <PDFDownloadLink
                        document={<QRCodePDF code={codePrintScanner} qrcode={qrCodeImage} />}
                        fileName={`QRCode-${codePrintScanner}.pdf`}
                    >
                        {({ blob, url, loading, error }) =>
                            loading ? (
                                <Button
                                    customClassName={styles.button}
                                    classColorButton={styles.saveText}
                                    onClick={() => {}}
                                    isLoading={loading}
                                    disabled={loading}
                                >
                                    <span>Carregando</span>
                                </Button>
                            ) : (
                                <Button
                                    customClassName={styles.button}
                                    classColorButton={styles.saveText}
                                    onClick={() => {
                                        setTimeout(() => {
                                            setQrCodeImage('');
                                            setCodePrintScanner('');
                                        }, 1000);
                                    }}
                                    isLoading={loading}
                                    disabled={loading}
                                >
                                    <span>Download</span>
                                </Button>
                            )
                        }
                    </PDFDownloadLink>
                )}
            </Modal.Footer>
        </Modal>
    );
}
