import styles from "./styles.module.scss";

const NotFound = () => (
  <div className={styles["container-page"]}>
    <div className={styles["error-page"]}>
      <div>
        <h1 data-h1="404">404</h1>
        <p data-p="NOT FOUND" className={styles["not-found"]}>Página não encontrada</p>
      </div>
      <div className={styles["tsparticles"]}></div>
    </div>
  </div>
);

export default NotFound;
