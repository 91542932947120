import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-typeahead/css/Typeahead.css";
import "react-bootstrap-typeahead/css/Typeahead.bs5.css";
import styles from "./styles.module.scss";
import SearchIcon from "../../assets/searchIcon.svg";

import { FormControl, InputAdornment, OutlinedInput } from "@mui/material";

interface SearchInputProps {
  placeholder: string;
  testId?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value?: string;
}

export default function SearchInput({
  placeholder,
  testId,
  value,
  onChange,
}: SearchInputProps) {
  return (
    <FormControl sx={{ m: 1, width: "42ch" }} variant="outlined">
      <OutlinedInput
        id={testId}
        type={"text"}
        onChange={onChange}
        value={value}
        className={styles.typeahead}
        placeholder={placeholder}
        endAdornment={
          <InputAdornment position="end">
            <div aria-label="toggle password visibility">
              <img src={SearchIcon} alt="pesquisar" />
            </div>
          </InputAdornment>
        }
      />
    </FormControl>
  );
}
