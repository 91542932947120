import AppNavbar from "layouts/AppNavbar";
import FeatureFlag from "pages/FeatureFlag";
import Home from "pages/Home";
import Invoices from "pages/Invoices";
import Login from "pages/Login";
import NotFound from "pages/NotFound";
import Splash from "pages/Splash";
import Versioning from "pages/Versioning";
import { createBrowserRouter } from "react-router-dom";
import ProtectedRoutes from "./ProtectedRoutes";

const router = createBrowserRouter(
  [
    {
      path: "/",
      element: <Splash />,
    },
    {
      path: "/login",
      element: <Login />,
    },
    {
      element: <ProtectedRoutes />,
      children: [
        {
          path: "/inicio",
          element: (
            <AppNavbar title="Portal de configuração" description="">
              <Home />
            </AppNavbar>
          ),
        },
        {
          path: "/gestao-funcionalidades",
          element: (
            <>
              <AppNavbar
                title="Gestão de Funcionalidades"
                description="Painel de controle para dar acesso às funcionalidades por unidade operacional."
                isGoBack
              >
                <FeatureFlag />
              </AppNavbar>
            </>
          ),
        },
        {
          path: "/versionamento",
          element: (
            <>
              <AppNavbar
                title="Versionamento"
                description="Histórico de versões do aplicativo, podendo controlar quais são obrigatórias"
                isGoBack
              >
                <Versioning />
              </AppNavbar>
            </>
          ),
        },
        {
          path: "/canhoto-digital",
          element: (
            <>
              <AppNavbar
                title="Canhoto Digital"
                description="Consulte ou extraia as informações do Canhoto Digital que desejar."
                isGoBack
              >
                <Invoices />
              </AppNavbar>
            </>
          ),
        },
      ],
    },
    {
      path: "*",
      element: <NotFound />,
    },
  ],
  { basename: "/" }
);

export default router;
