import ButtonComponent from "components/ButtonComponent";
import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  padding-bottom: 10%;
  font-family: "Regular" !important;
`;

export const BorderTable = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px 39px;
  gap: 24px;
  min-width: 1100px;

  width: 100%;
  border: 2.5px solid ${({ theme }) => theme.colors.yellow_ipiranga};
`;

export const ContainerTypeShipping = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const TitleInput = styled.span`
  font-family: "Medium";
  font-style: normal;
  font-weight: bold;
  margin-right: 12px;
  line-height: 150%;
  color: #00266e;
`;

export const ButtonInclude = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px;
  gap: 8px;
  width: 150px;
  background: ${({ theme }) => theme.colors.blue_ipiranga};
  border-radius: 100px;
`;

export const CalendarArea = styled.div`
  position: relative;
  display: flex;
  margin-right: 23px;
  align-items: center;
`;

export const ContainerDate = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 37px;
`;

export const ContainerDefaultSearch = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`;
export const DefautSearchArea = styled.div`
  width: 30%;
  margin-right: 23px;
`;
export const ContainerTitleAdvancedSearch = styled.div``;

export const ContainerAdvancedSearch = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;
export const ContentAdvancedSearch = styled.div`
  width: 30%;
`;
export const AdvancedSearchArea = styled.div`
  margin-bottom: 23px;
`;

export const ContainerButton = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
`;
export const Button = styled(ButtonComponent).attrs({
  style: {
    paddingLeft: 59,
    paddingRight: 59,
    paddingTop: 19,
    paddingBottom: 19,
    marginRight: 24,
    fontWeight: "bold",
  },
})``;

export const ContainerVolume = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;
export const ContainerFeedbackNoResult = styled.div`
  width: 100%;
  margin-top: 28px;
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const IconAlert = styled.img`
  width: 161px;
  height: 161px;
  margin: 38px 0 15px 0;
  align-self: center;
`;
