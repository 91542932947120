import styles from "./styles.module.scss";

interface FeatureCardProps {
  title?: string;
  color?: string;
  backgroundColor?: string;
  onClick?: () => void;
  testId?: string;
  img: any;
}

const FeatureCard = ({
  title,
  color,
  img,
  backgroundColor,
  onClick = () => {},
  testId,
}: FeatureCardProps) => (
  <div
    data-testid={testId}
    className={styles.card}
    style={{ background: backgroundColor }}
    onClick={onClick}
  >
    <span
      className={`${styles.title}`}
      style={{
        color: color,
      }}
    >
      {title}
    </span>

    <img alt="" src={img} />
  </div>
);

export default FeatureCard;
