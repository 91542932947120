import { Navigate } from "react-router-dom";

const Splash = () => {
  const localStorageToken = localStorage.getItem("token");

  if (localStorageToken) {
    return <Navigate to="/inicio" replace={true} />;
  }

  return <Navigate to="/login" replace={true} />;
};

export default Splash;
