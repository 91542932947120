import api from 'Services/api';
import { ImageS3Props, PostFormFields } from './types';

export const getSearchInvoices = async (
    data: PostFormFields,
    itensPorPagina = 10,
    pagina = 1
): Promise<any> => {
    const body = {
        itensPorPagina: itensPorPagina.toString(),
        pagina,
        modalidadeFrete: {
            cif: data.modalidadeFrete.cif,
            fob: data.modalidadeFrete.fob,
        },
    } as any;

    if (data.emitente) {
        body.emitente = data.emitente;
    }
    if (data.cliente) {
        body.cliente = data.cliente;
    }
    if (data.numeroNF.length > 0 && data.numeroNF[0] !== '') {
        body.numeroNF = data.numeroNF;
    }
    if (data.dataEmissaoNFe.periodoAte) {
        body.dataEmissaoNFe = {
            periodoDe: data.dataEmissaoNFe.periodoDe?.split('T')[0],
            periodoAte: data.dataEmissaoNFe.periodoAte?.split('T')[0],
        };
    }

    if (data.dataBaixaCanhoto.periodoDe !== null || data.dataBaixaCanhoto.periodoAte !== null) {
        let currentDate = new Date();
        let oldDate = new Date(2021, 0, 1);
        body.dataBaixaCanhoto = {
            periodoDe: data.dataBaixaCanhoto.periodoDe?.split('T')[0]
                ? data.dataBaixaCanhoto.periodoDe?.split('T')[0]
                : oldDate.toISOString().split('T')[0],
            periodoAte: data.dataBaixaCanhoto.periodoAte?.split('T')[0]
                ? data.dataBaixaCanhoto.periodoAte?.split('T')[0]
                : currentDate.toISOString().split('T')[0],
        };
    }

    if (data.statusCanhoto) {
        body.statusCanhoto = data.statusCanhoto;
    }

    if (data.chaveNFe.length > 0 && data.chaveNFe[0] !== '') {
        body.chaveNFe = data.chaveNFe;
    }

    if (data.serie) {
        body.serie = data.serie;
    }

    if (data.produto) {
        body.produto = data.produto.toUpperCase();
    }

    if (data.volume.rangeDe) {
        body.volume = {
            rangeDe: Number(data.volume.rangeDe),
            rangeAte: Number(data.volume.rangeAte),
        };
    }

    if (data.numeroPedido) {
        body.numeroPedido = data.numeroPedido;
    }

    if (data.modalidadeFrete.cif || data.modalidadeFrete.fob) {
        body.modalidadeFrete = {
            cif: data.modalidadeFrete.cif,
            fob: data.modalidadeFrete.fob,
        };
    }

    if (data.nomeTransportador) {
        body.nomeTransportador = data.nomeTransportador;
    }

    if (data.CNPJTransportador) {
        body.cnpjTransportador = data.CNPJTransportador;
    }

    if (data.codigoTransportador) {
        body.codigoTransportador = data.codigoTransportador;
    }

    try {
        const { data } = await api.post('/motorista/consulta-notas', body);
        return data;
    } catch (error) {
        throw error;
    }
};

export const getImageAssign = async (data: any): Promise<any> => {
    try {
        const idsS3 = data.split(',');

        let dataResponse: ImageS3Props[] = [];

        return new Promise((resolve, reject) => {
            for (const ids of idsS3) {
                const headers = {
                    imagens: ids,
                };

                api.get('/motorista/consulta-assinaturas-notas', {
                    headers,
                    timeout: 150000,
                })
                    .then((response) => {
                        dataResponse.push(response.data.data[0]);
                        if (dataResponse.length === idsS3.length) {
                            resolve(dataResponse);
                        }
                    })
                    .catch((error) => {
                        reject(error);
                    });
            }
        });
    } catch (error) {
        console.log(error);
    }
};
