
import styles from './styles.module.scss';

const LoadingCircle = () => {
  return (
    <div className={`${styles.rotate} ${styles.loading}`} />
  );
};

export default LoadingCircle;
