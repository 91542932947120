import Modal from "react-bootstrap/Modal";
import styles from "./styles.module.scss";
import SaveCancelButtons from "../SaveCancelButtons";

interface ConfirmationModalProps {
  show: boolean;
  handleClose?: () => void;
  headerContent?: string;
  onSubmit?: () => void;
  hasCloseButton?: boolean;
  saveButtonText?: string;
  cancelButtonText?: string;
  testId?: string;
  loading?: boolean;
}

export default function ConfirmationModal({
  show = false,
  handleClose = () => {},
  headerContent = "",
  onSubmit = () => {},
  hasCloseButton = true,
  saveButtonText = "Salvar",
  cancelButtonText = "Cancelar",
  testId,
  loading,
}: ConfirmationModalProps) {
  return (
    <Modal
      data-testid={testId}
      className={`${styles.modal} `}
      show={show}
      onHide={handleClose}
      size="xl"
    >
      <Modal.Header
        className={styles.header}
        closeButton={!!hasCloseButton}
        style={{ border: "none" }}
      >
        {!!headerContent && (
          <Modal.Title className={styles.title}>{headerContent}</Modal.Title>
        )}
      </Modal.Header>

      <Modal.Footer style={{ border: "none" }} className={styles.footer}>
        <SaveCancelButtons
          areaClassName={styles.areaClassName}
          saveButton={styles.saveButton}
          saveFunc={onSubmit}
          loading={loading}
          cancelFunc={handleClose}
          saveButtonAlternativeText={saveButtonText}
          cancelButtonAlternativeText={cancelButtonText}
        />
      </Modal.Footer>
    </Modal>
  );
}
