import Modal from "react-bootstrap/Modal";
import styles from "./styles.module.scss";
// import InvoicePDFModal from "components/InvoicePDFModal";
import { PDFViewer, StyleSheet } from "@react-pdf/renderer";
import InvoicePDFModal from "components/InvoicePDFModal";
import LoadingCircle from "components/LoadingCircle";
import { useState } from "react";
import { CSVLink } from "react-csv";
import {
  generateZipPdf,
  invoicesToCsv,
  nameFileCSV,
} from "utils/basic/invoices";
import Button from "../../components/Button";

import { InvoicesBack } from './types';

const style = StyleSheet.create({
  viewer: {
    width: "100%", //the pdf viewer will take up all of the width and height
    height: "40rem",
  },
});

interface ExportPDFModalProps {
  show: boolean;
  handleClose?: () => void;
  headerContent?: string;
  clearSelected?: () => void;
  hasCloseButton?: boolean;
  saveButtonText?: string;
  cancelButtonText?: string;
  showFeedback: () => void;
  notas: InvoicesBack["notas"];
  testId?: string;
  loading?: boolean;
}

export default function ExportPDFModal({
  show = false,
  handleClose = () => {},
  clearSelected = () => {},
  hasCloseButton = true,
  testId,
  showFeedback,
  notas,
  loading,
}: ExportPDFModalProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [csvData, setCsvData] = useState<string[][]>();

  return (
    <Modal data-testid={testId} show={show} onHide={handleClose} size="xl">
      <Modal.Header closeButton={!!hasCloseButton} style={{ border: "none" }}>
        <Modal.Title className={styles.title}>
          Canhotos selecionados
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <PDFViewer style={style.viewer}>
          <InvoicePDFModal notas={notas} />
        </PDFViewer>
      </Modal.Body>
      <Modal.Footer style={{ border: "none" }}>
        <div className={`${styles.actionsButtons} `}>
          {!isLoading ? (
            <Button
              classColorButton={styles.textPDF}
              customClassName={styles.buttonPDF}
              disabled={loading}
              onClick={async () => {
                setIsLoading(true);
                generateZipPdf({
                  notas,
                  component: notas.map((item) => (
                    <InvoicePDFModal notas={[item]} />
                  )),
                  finishLoad: () => {
                    setIsLoading(false);
                    clearSelected();
                  },
                });
                showFeedback();
              }}
            >
              <span>Baixar em PDF</span>
            </Button>
          ) : (
            <LoadingCircle />
          )}
          <Button
            customClassName={`${styles.buttonCSV} `}
            isLoading={loading}
            onClick={() => {
              showFeedback();
              clearSelected();
              setCsvData(invoicesToCsv(notas));
            }}
            disabled={loading}
          >
            <CSVLink
              filename={nameFileCSV + ".csv"}
              className={styles.csvLink}
              target="_blank"
              data={csvData || []}
            >
              Exportar para excel
            </CSVLink>
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
