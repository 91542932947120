import { Pagination } from '@mui/material';
import ButtonComponent from 'components/ButtonComponent';
import LoadingCircle from 'components/LoadingCircle';
import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import TrashIcon from '../../assets/trash-icon.svg';
import {
    ContainerButton,
    ContainerPagination,
    LoadingView,
    Separate,
    Table,
    TableCell,
    TableHeader,
    TableHeaderRow,
    TableRow,
    TextList,
    TextTitle,
} from './styled';
import { IListProps, IRenderList, RenderList } from './types';
import { render } from '@testing-library/react';

const ListInvoicesComponent: React.FC<IListProps> = ({
    invoicesData,
    headerTitles,
    isCheckBox,
    handleRemoveInvoice,
    changePage,
    onExportPDF,
    onExportCSV,
    onViewInvoice,
    setSelectAllChecked,
    quatityPerPage = 10,
    quantityAll,
    isSelectAll: selectAllChecked,
    onSelectAll,
    quantityTotalPages,
    onSelect,
    page,
    loading,
    keyExportButton,
    selectedListItem,
}) => {
    const [checkedItems, setCheckedItems] = useState<{ [key: number]: boolean }>({});
    const [renderInvoices, setRenderInvoices] = useState<IRenderList[]>();

    const formatDate = (date: string) => {
        return date.substring(8, 10) + '/' + date.substring(5, 7) + '/' + date.substring(0, 4);
    };

    const paginate = (pageNumber: number) => {
        changePage && changePage(pageNumber);
    };

    useEffect(() => {
        if (selectedListItem?.length === 0) {
            setCheckedItems({});
        }
    }, [selectedListItem]);

    useEffect(() => {
        if (invoicesData) {
            const extractedRenderListData: IRenderList[] = invoicesData.map((invoice) => {
                const {
                    emitente,
                    cliente,
                    modalidadeFrete,
                    numeroNF,
                    dataEmissaoNFe,
                    dataBaixaCanhoto,
                    statusCanhoto,
                    chaveNFe,
                } = invoice;

                return {
                    emitente: emitente.codigo,
                    cliente: cliente.nome,
                    modalidadeFrete: ['1', '2', '4'].includes(modalidadeFrete) ? 'FOB' : 'CIF',
                    numeroNF,
                    dataEmissaoNFe: formatDate(dataEmissaoNFe),
                    dataBaixaCanhoto:
                        dataBaixaCanhoto && dataBaixaCanhoto !== 'null'
                            ? formatDate(dataBaixaCanhoto)
                            : '-',
                    statusCanhoto,
                    chaveNFe,
                };
            });
            setRenderInvoices(extractedRenderListData);
            if (selectAllChecked) {
                onSelectAll && onSelectAll(false);
                setSelectAllChecked && setSelectAllChecked(false);
                setCheckedItems({});
            }
        }
    }, [invoicesData]);

    const hasCheckedValue = Object.values(checkedItems).some((value) => value === true);

    const handleSelectAllCheckboxChange = () => {
        const newCheckedItems: { [key: number]: boolean } = {};
        if (!selectAllChecked) {
            renderInvoices?.forEach((invoice, index) => {
                newCheckedItems[Number(invoice.numeroNF)] = true;
            });
        }
        setCheckedItems(newCheckedItems);
        setSelectAllChecked && setSelectAllChecked(!selectAllChecked);
        onSelectAll && onSelectAll(!selectAllChecked);
    };

    const handleCheckboxById = (id: number) => {
        setCheckedItems({
            ...checkedItems,
            [id]: !checkedItems[id],
        });

        const invoice = invoicesData.find((invoice) => Number(invoice.numeroNF) === id);
        onSelect && onSelect(invoice as any);
    };

    return (
        <>
            <Table>
                <TableHeader>
                    <TableHeaderRow>
                        {isCheckBox && (
                            <TableCell>
                                <Form.Check
                                    style={{
                                        marginRight: 12,
                                        color: '#0068FF',
                                        fontWeight: 'bold',
                                    }}
                                    checked={selectAllChecked}
                                    onChange={handleSelectAllCheckboxChange}
                                />
                            </TableCell>
                        )}
                        {headerTitles.map((title) => (
                            <TableCell key={title}>
                                <TextTitle>{title}</TextTitle>
                            </TableCell>
                        ))}
                    </TableHeaderRow>
                </TableHeader>
                <tbody>
                    {renderInvoices?.map((invoice, index) => (
                        <TableRow key={(invoice as any).chaveNFe}>
                            {isCheckBox && (
                                <TableCell>
                                    <Form.Check
                                        style={{
                                            marginRight: 12,
                                            color: '#0068FF',
                                            fontWeight: 'bold',
                                        }}
                                        checked={checkedItems[Number(invoice.numeroNF)] || false}
                                        onChange={() =>
                                            handleCheckboxById(Number(invoice.numeroNF))
                                        }
                                    />
                                </TableCell>
                            )}
                            {RenderList.map((title) => (
                                <TableCell key={title}>
                                    <TextList isChecked={checkedItems[Number(invoice.numeroNF)]}>
                                        {invoice[title as keyof IRenderList]?.toString()}
                                    </TextList>
                                </TableCell>
                            ))}
                            {!isCheckBox && (
                                <TableCell
                                    onClick={() =>
                                        handleRemoveInvoice && handleRemoveInvoice(invoice.numeroNF)
                                    }
                                >
                                    <img
                                        style={{ cursor: 'pointer' }}
                                        src={TrashIcon}
                                        alt="Icone de lixeira"
                                    />
                                </TableCell>
                            )}
                        </TableRow>
                    ))}
                </tbody>
            </Table>
            {isCheckBox && (
                <ContainerPagination>
                    <Pagination
                        color="primary"
                        size="small"
                        count={quantityTotalPages}
                        page={page}
                        onChange={(event, page) => paginate(page)}
                    />
                </ContainerPagination>
            )}

            {hasCheckedValue ? (
                <>
                    <Separate />
                    <ContainerButton>
                        {!loading ? (
                            <>
                                <ButtonComponent
                                    style={{ marginRight: 20 }}
                                    typeStyle="default"
                                    data-testid="button-new-version"
                                    onClick={onViewInvoice}
                                    disabled={loading}
                                >
                                    Visualizar canhoto(s) selecionado(s)
                                </ButtonComponent>
                                <ButtonComponent
                                    style={{ marginRight: 20 }}
                                    typeStyle="default"
                                    data-testid="button-new-version"
                                    onClick={onExportPDF}
                                    disabled={loading}
                                    key={keyExportButton}
                                >
                                    Exportar em PDF
                                </ButtonComponent>
                            </>
                        ) : (
                            <LoadingView>
                                <LoadingCircle />
                            </LoadingView>
                        )}
                        <ButtonComponent
                            typeStyle="outlined"
                            data-testid="button-new-version"
                            onClick={onExportCSV}
                            disabled={loading}
                        >
                            Exportar para Excel
                        </ButtonComponent>
                    </ContainerButton>
                    <Separate />
                </>
            ) : (
                isCheckBox && (
                    <>
                        <Separate />
                        <ContainerButton>
                            <ButtonComponent
                                typeStyle="outlined"
                                data-testid="button-new-version"
                                onClick={onExportCSV}
                            >
                                Exportar para Excel
                            </ButtonComponent>
                        </ContainerButton>
                        <Separate />
                    </>
                )
            )}
        </>
    );
};

export default ListInvoicesComponent;
