// import { styled } from "@mui/material/styles";
import { styled } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/pt-br';

interface InputDateProps {
    testId?: string;
    label: string;
    onChange: (date: Dayjs | null, validationError?: null | string) => void;
    value?: Dayjs | null;
    error?: boolean;
    helperText?: string;
    maxDate?: Dayjs;
}

export default function InputDate({
    label,
    onChange,
    value,
    error,
    helperText,
    maxDate,
}: InputDateProps) {
    const DatePickerStyled = styled(DatePicker)({
        '.MuiInputBase-root': {
            '&:hover': {
                borderColor: '#0068FF',
            },
            '&.Mui-focused': {
                borderColor: '#0068FF',
            },
            '&.Mui-error': {
                borderColor: '#FF4D4F',
            },
        },
        '.MuiInputLabel-root': {
            color: '#58595B',
            fontSize: '16px',
            lineHeight: '18px',
            '&.Mui-focused': {
                color: '#0068FF',
            },
        },
        '.MuiFormHelperText-root': {
            color: '#FF4D4F',
            fontSize: '12px',
        },
        '.MuiOutlinedInput-input': {
            padding: '14px',
            paddingRight: '0',
            with: '100%',
            borderColor: '#000',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#58595B',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#58595B',
                borderRadius: '100px',
            },
            '&:hover fieldset': {
                borderColor: '#58595B',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#0068FF',
            },
        },
    });

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={['DatePicker']}>
                <DatePickerStyled
                    format={'DD/MM/YYYY'}
                    onChange={(value: Dayjs | null, context) => {
                        if (dayjs.isDayjs(value)) {
                            onChange(value);
                        } else {
                            onChange(null, context.validationError);
                        }
                    }}
                    slotProps={{
                        textField: {
                            error: error,
                            helperText: !error || helperText,
                        },
                    }}
                    value={value}
                    label={label}
                    minDate={dayjs('01/01/2023')}
                    maxDate={maxDate}
                />
            </DemoContainer>
        </LocalizationProvider>
    );
}
