async function generateKey() {
    return crypto.subtle.generateKey(
        {
        name: "AES-CTR",
        length: 128,
        hash: "SHA-256"
        },
        true, 
        ["encrypt", "decrypt"] 
    );
}

export async function encrypt(data: string) {
    const encoder = new TextEncoder();
    const encodedData = encoder.encode(data);
    const iv = crypto.getRandomValues(new Uint8Array(16)); 

    const encryptedData = await crypto.subtle.encrypt(
        {
        name: "AES-CTR",
        length: 128,
        counter: iv,
        },
        await generateKey(),
        encodedData
    );

    return arrayBufferToBase64(encryptedData)
}

function arrayBufferToBase64(buffer: ArrayBuffer) {
    let binary = '';
    let bytes = new Uint8Array(buffer);
    for (let i = 0; i < bytes.byteLength; i++) {
        binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
}