import styles from "./styles.module.scss";

import { useMsal } from "@azure/msal-react";

import { useEffect, useState } from "react";
import Button from "../../components/Button";

import {
  InteractionRequiredAuthError,
  InteractionStatus,
} from "@azure/msal-browser";

const Login = () => {
  const { instance, accounts, inProgress } = useMsal();
  const [loading, setLoading] = useState(false);

  const tokenCache = localStorage.getItem("token");

  useEffect(() => {
    if (
      !loading &&
      inProgress === InteractionStatus.None &&
      accounts.length > 0
    ) {
      setLoading(true);
      if (tokenCache) {
        // Skip data refresh if already set - adjust logic for your specific use case
        return;
      }

      const tokenRequest = {
        account: accounts[0], // This is an example - Select account based on your app's requirements
        scopes: ["User.Read"],
      };

      instance
        .acquireTokenSilent(tokenRequest)
        .then((response) => {
          localStorage.setItem("token", JSON.stringify(response));
          window.location.href = "/inicio";
        })
        .catch(async (e) => {
          if (e instanceof InteractionRequiredAuthError) {
            await instance.acquireTokenRedirect(tokenRequest);
          }

          throw e;
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [inProgress, accounts, instance, loading, tokenCache]);

  const onLogin = async () => {
    setLoading(true);
    instance.loginRedirect({
      scopes: ["User.Read"],
    });
  };

  return (
    <>
      <div className={styles["container"]}>
        <div className={styles["content"]}>
          <div className={styles["center"]}>
            <span className={styles["title"]}>Portal Ipicarga</span>
            <span className={styles["subTitle"]}>
              Bem-vindo ao portal de configuração interno da Ipiranga
            </span>
            <div className={styles.buttonArea}>
              <Button
                isLoading={loading}
                customClassName={styles.button}
                onClick={onLogin}
              >
                Clique para entrar
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div className={styles["footer"]}>
        <span>
          Uma empresa do grupo Ultra     Ultragaz   |   Ultracargo   |  
          Copyright © Ipiranga 1999-2015
        </span>
      </div>
    </>
  );
};

export default Login;
